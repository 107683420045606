import React, { useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { useData } from '../../../Contexts/DataProvider/useDataAggregation'
import { Dayjs } from 'dayjs'
import { calculateBreakdown } from './util'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import Container from './Container'
import useFormatValue from '../../../Hooks/useFormatValue'
import { colors } from '../../../theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    bolded: {
      fontWeight: 'bolder',
    },
    avgRow: {},
  })
)

export interface FullTableOwnProps {
  focusedDay: Dayjs
}

export type FullTableProps = FullTableOwnProps

const FullTable = (props: FullTableProps) => {
  const { data } = useData()
  const breakdown = useMemo(() => calculateBreakdown(props.focusedDay, data), [
    props.focusedDay,
    data,
  ])
  const classes = useStyles()
  const formatValue = useFormatValue()
  const currentStyle: React.CSSProperties = {
    color: colors.contrast,
    fontWeight: 'bolder',
  }
  return (
    <Container title="ISO Week Performance" className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.bolded}>{'ISO Week'}</TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Monday'}
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Tuesday'}
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Wednesday'}
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Thursday'}
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Friday'}
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Saturday'}
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Sunday'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {breakdown.weeks.map((week) => (
            <TableRow key={week.isoWeek}>
              <TableCell className={classes.bolded}>
                {week.isCurrent ? 'Current' : week.isoWeek}
              </TableCell>
              {week.days.map((day) => (
                <TableCell
                  align="right"
                  key={day.isoDayOfWeek}
                  style={day.isCurrent ? currentStyle : {}}
                >
                  {formatValue(day.value)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow className={classes.avgRow}>
            <TableCell className={classes.bolded}>{'Average'}</TableCell>
            {breakdown.dayAvg.map((day) => (
              <TableCell
                align="right"
                key={day.isoDayOfWeek}
                className={classes.bolded}
              >
                {formatValue(day.avgValue)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  )
}

export default React.memo(FullTable)
