import AnalyticsDataPoint from './AnaltyicsDataPoint'
import _groupBy from 'lodash/groupBy'
import _sum from 'lodash/sum'
import _mean from 'lodash/mean'
import dayjs, { Dayjs } from 'dayjs'
import weekYear from 'dayjs/plugin/weekYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export type IMergeBy = (
  dataPoints: AnalyticsDataPoint[]
) => AnalyticsDataPoint[]

type Spans = 'hour' | 'day' | 'week' | 'month' | 'year'

const groupBy = (span: Spans, dataPoints: AnalyticsDataPoint[]) => {
  const getKey = (date: Dayjs) => {
    let key = `${date.year()}`
    if (span === 'year') {
      return key
    }

    key += `-${date.month()}`
    if (span === 'month') {
      return key
    }

    key += `-${date.weekYear()}`
    if (span === 'week') {
      return key
    }

    key += `-${date.dayOfYear()}`
    if (span === 'day') {
      return key
    }

    key += `-${date.hour()}`
    if (span === 'hour') {
      return key
    }
  }

  const groupHash = _groupBy(dataPoints, (_) => getKey(_.date))
  return Object.values(groupHash)
}

export type IMergeResolver = (values: number[]) => number

const resolveGroupings = (
  span: Spans,
  groupings: AnalyticsDataPoint[][],
  resolver: IMergeResolver
): AnalyticsDataPoint[] => {
  const resolved = groupings
    .filter((_) => !!_?.length)
    .map((grouping) => {
      const groupingValues = grouping.map((_) => _.value)
      const resolvedValue = resolver(groupingValues)
      const resolvedDate = grouping[0]?.date.startOf(span)
      return new AnalyticsDataPoint(resolvedDate, resolvedValue)
    })
  return resolved
}

export const resolvers = {
  sum: _sum,
  mean: _mean,
}

const getMerger = (span: Spans, mergeResolver: IMergeResolver): IMergeBy => (
  dataPoints: AnalyticsDataPoint[]
): AnalyticsDataPoint[] => {
  const groupings = groupBy(span, dataPoints)
  return resolveGroupings(span, groupings, mergeResolver)
}

export const mergeBy = {
  hour: getMerger('hour', resolvers.sum),
  day: getMerger('day', resolvers.sum),
  week: getMerger('week', resolvers.sum),
  month: getMerger('month', resolvers.sum),
  year: getMerger('year', resolvers.sum),
}
