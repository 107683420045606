import firebase from 'firebase/app'
import useToggle from '../../Hooks/useToggle'

export type ReturnValue = {
  isLoading: boolean
  onManageSubscription: () => void
}

function useManageSubscription() {
  const [isLoading, setLoading] = useToggle(false)
  const onManageSubscription = async () => {
    setLoading()
    const functionRef = firebase
      .app()
      .functions('us-central1')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')

    try {
      const { data } = await functionRef({ returnUrl: window.location.origin })
      window.location.assign(data.url)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    isLoading,
    onManageSubscription,
  }
}

export default useManageSubscription
