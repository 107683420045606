import {
  WindowConfig,
  getCurrentReferenceDate,
  evaluateWindow,
} from '../../util/windowingUtils'
import { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import { sumProjection } from '../../Contexts/DataProvider/AnalyticsData/Projections'
import { useData } from '../../Contexts/DataProvider/useDataAggregation'
import { selectBeforeRelativeFrame } from '../../Contexts/DataProvider/AnalyticsData/Selections'

export type TrackWinowedProgressOptions = {
  /**
   * Force the ref to be cutoff to the current time in frame.
   * Example, if comparing current day to yesterday, only the currencompare up to t time of day
   */
  forceRelativeFrame: boolean
  referenceDate?: Dayjs
}

const useTrackWindowedProgress = (
  trackWindow: WindowConfig,
  refWindow: WindowConfig,
  options: TrackWinowedProgressOptions
) => {
  const { data } = useData()
  const referenceDate = useMemo(
    () => options.referenceDate || getCurrentReferenceDate(),
    [options.referenceDate]
  )
  const trackValue = useMemo(
    () =>
      evaluateWindow(trackWindow, referenceDate, data).project(sumProjection)
        .value,
    [trackWindow, referenceDate, data]
  )

  const refValue = useMemo(() => {
    let selection = evaluateWindow(refWindow, referenceDate, data)
    if (options.forceRelativeFrame) {
      selection = selection.select(selectBeforeRelativeFrame(trackWindow.frame))
    }
    return selection.project(sumProjection).value
  }, [
    refWindow,
    referenceDate,
    data,
    options.forceRelativeFrame,
    trackWindow.frame,
  ])

  return {
    trackValue,
    refValue,
  }
}

export default useTrackWindowedProgress
