import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import RootContext from './RootContext'
import Routes from './Routes'
import Notifications from './Notifications'
import { ThemeProvider } from '@material-ui/core'
import customTheme from './theme'
import AccountInitialization from './SharedComponents/AccountInitialization'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import { firebaseConfig } from './util/constants'
import useAuthState from './Hooks/userPersistedAuth'
import DataProvider from './Contexts/DataProvider'
import Div100vh from 'react-div-100vh'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import 'antd/dist/antd.css'
import { startBugsnagClient } from './util/bugsnagUtil'

startBugsnagClient()

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app() // if already initialized, use that one
}
firebase.analytics()

const stripePromise = loadStripe(
  'pk_live_51IImHiCihcRjp8IGlcMkjzm8ve0dPYx5kroioUNi0nfPLKPSHmpScs9bH5x5v1mfNbmsFkNlyMZTobUPPBzrIv7u00dGcjleIL'
)

function App() {
  const { authState, setAuthState } = useAuthState()

  return (
    <Elements stripe={stripePromise}>
      <Div100vh>
        <ThemeProvider theme={customTheme}>
          <Notifications>
            <Router>
              <RootContext authState={authState} setAuthState={setAuthState}>
                <AccountInitialization>
                  <DataProvider>
                    <Routes />
                  </DataProvider>
                </AccountInitialization>
              </RootContext>
            </Router>
          </Notifications>
        </ThemeProvider>
      </Div100vh>
    </Elements>
  )
}

export default App
