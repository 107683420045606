import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, useTheme, fade } from '@material-ui/core'
import { motion } from 'framer-motion'
import { useLocation, useHistory } from 'react-router-dom'
import { colors } from '../../theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: theme.spacing(2),
      borderRadius: `0 26px 26px 0`,
      marginBottom: theme.spacing(2),
    },
    topContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    secondaryText: {
      marginTop: theme.spacing(2),
    },
  })
)

export interface SidebarItemOwnProps {
  icon: any
  text: string
  subtext?: string
  route: string
}

export type SidebarItemProps = SidebarItemOwnProps

const SidebarItem = (props: SidebarItemProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const { push } = useHistory()

  const variants = {
    hover: {
      backgroundColor: fade(colors.opposite, 0.1),
      color: colors.opposite,
      stroke: colors.opposite,
    },
    unselected: {
      backgroundColor: fade(colors.opposite, 0),
      color: theme.palette.text.secondary,
      stroke: theme.palette.text.secondary,
    },
    selected: {
      backgroundColor: fade(colors.opposite, 0.1),
      color: colors.opposite,
      stroke: colors.opposite,
    },
  }

  return (
    <motion.div
      whileHover="hover"
      variants={variants}
      className={classes.root}
      animate={props.route === location.pathname ? 'selected' : 'unselected'}
      onClick={() => push(props.route)}
      transition={{ duration: 0.7 }}
    >
      <div className={classes.topContainer}>
        <props.icon color="inherit" className={classes.icon} />
        <Typography color="inherit" variant="body1">
          {props.text}
        </Typography>
      </div>
      {!!props.subtext && (
        <Typography
          className={classes.secondaryText}
          variant="caption"
          color="inherit"
        >
          {props.subtext}
        </Typography>
      )}
    </motion.div>
  )
}

export default React.memo(SidebarItem)
