import dayjs, { Dayjs } from 'dayjs'
import _isNumber from 'lodash/isNumber'
import { Row } from '../../../services/GoogleAPIService'
import { Range } from '../useAPIQuery'
import { PersistedDataPoint } from '../PersistedData'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const apiHourBucketRegex = /(\d+)-?(?:\d+)?.*/

export default class AnalyticsDataPoint {
  public readonly date: Dayjs
  public readonly value: number

  static initFromAPIDataPoint(row: Row, dateRange: Range): AnalyticsDataPoint {
    const rawHourBucketValue = row?.dimensions?.[0] || ''
    const rawValue = row?.metrics?.[0]?.values?.[0] || ''

    const parsedBucketValue = rawHourBucketValue.match(apiHourBucketRegex)?.[1]
    let numericHourBucketValue = NaN
    if (parsedBucketValue) {
      numericHourBucketValue = Number(parsedBucketValue)
    }

    let date = dayjs('foo') // purposely invalid
    if (!isNaN(numericHourBucketValue)) {
      date = dateRange.start.add(numericHourBucketValue, 'hours')
    }

    return new AnalyticsDataPoint(date, Number(rawValue))
  }

  static initFromPersistedDataPoint(
    persistedDataPoint: PersistedDataPoint
  ): AnalyticsDataPoint {
    return new AnalyticsDataPoint(
      dayjs(persistedDataPoint.date),
      persistedDataPoint.value
    )
  }

  constructor(date: Dayjs, value: number) {
    if (!date.isUTC()) {
      this.date = date.utc() // strange comparison behavior occurs if not in utc
    } else {
      this.date = date
    }

    this.value = value
  }

  isValid() {
    return !!this.date && !!this.date.isValid?.() && _isNumber(this.value)
  }

  get asPersistedDatapoint(): PersistedDataPoint {
    return {
      date: this.date.toISOString(),
      value: this.value,
    }
  }

  get dateCompValue(): number {
    return this.date.unix()
  }
}
