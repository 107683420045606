import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import AnalyticsDataCollection from '../../../Contexts/DataProvider/AnalyticsData/AnalyticsDataCollection'
import {
  isBefore,
  isSameOrAfter,
  isSameOrBefore,
} from '../../../Contexts/DataProvider/AnalyticsData/Selections'
import _flatten from 'lodash/flatten'
import _mean from 'lodash/mean'
import _sum from 'lodash/sum'
import { sumProjection } from '../../../Contexts/DataProvider/AnalyticsData/Projections'

dayjs.extend(isoWeek)

export type ISODayBreakdown = {
  isoDayOfWeek: number
  value: number
  isCurrent: boolean
}

export type ISOWeekBreakdown = {
  isoWeek: number
  isCurrent: boolean
  days: ISODayBreakdown[]
  total: number
}

export type ISOAverage = {
  isoDayOfWeek: number
  avgValue: number
}

export type ISOBreakdown = {
  weeks: ISOWeekBreakdown[]
  dayAvg: ISOAverage[]
  avg: number
}

const calculateDay = (
  day: Dayjs,
  data: AnalyticsDataCollection,
  isCurrent: boolean
): ISODayBreakdown => {
  const dayTotal =
    data

      .setAnchor(day)
      .select(
        isSameOrAfter((_) => _.startOf('day')),
        isBefore((_) => _.endOf('day'))
      )
      .project(sumProjection).value || 0

  return {
    isoDayOfWeek: day.isoWeekday(),
    value: dayTotal,
    isCurrent,
  }
}

const computeAverage = (isoDayOfWeek: number, isoWeeks: ISOWeekBreakdown[]) => {
  const dayValues = _flatten(
    isoWeeks.map((_) => _.days.filter((_) => _.isoDayOfWeek === isoDayOfWeek))
  ).map((_) => _.value || 0)

  return _mean(dayValues)
}

const COUNT_BACK_WEEKS = 5

export const calculateBreakdown = (
  focusedDay: Dayjs,
  data: AnalyticsDataCollection
): ISOBreakdown => {
  const startDay = focusedDay

    .startOf('day')
    .startOf('isoWeek')
    .subtract(COUNT_BACK_WEEKS, 'week')
  const absoluteEnd = focusedDay.endOf('isoWeek')

  const initialFilter = data

    .setAnchor(startDay)
    .select(isSameOrAfter((_) => _.startOf('day')))
    .setAnchor(absoluteEnd)
    .select(isSameOrBefore((_) => _.endOf('day')))

  const weeks: ISOWeekBreakdown[] = []

  for (let week = 0; week < COUNT_BACK_WEEKS + 1; week++) {
    const weekStart = startDay.add(week, 'week')
    const isoDays: ISODayBreakdown[] = []
    for (let day = 0; day < 7; day++) {
      const dayStart = weekStart.add(day, 'day')
      const isCurrent = focusedDay.startOf('day').isSame(dayStart, 'day')
      isoDays.push(calculateDay(dayStart, initialFilter, isCurrent))
    }

    const isCurrent = focusedDay.isoWeek() === weekStart.isoWeek()
    weeks.push({
      isoWeek: weekStart.isoWeek(),
      isCurrent,
      days: isoDays,
      total: _sum(isoDays.map((_) => _.value)),
    })
  }

  const averages: ISOAverage[] = []
  for (let day = 1; day <= 7; day++) {
    averages.push({
      isoDayOfWeek: day,
      avgValue: computeAverage(day, weeks),
    })
  }

  return {
    weeks,
    dayAvg: averages,
    avg: _mean(weeks.map((_) => _.total)),
  }
}
