import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { useAlert } from '../../Notifications'
import { UpdateAuthContext } from '../../Contexts/AuthContext'
import useIsAuthenticated from '../../Hooks/useIsAuthenticated'
import { Redirect, useHistory } from 'react-router-dom'
import routes from '../../util/routes'
import LoadingView from '../../SharedComponents/LoadingView'
import { useEffectOnce } from 'react-use'
import firebase from 'firebase/app'
import 'firebase/auth'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    loginButton: {
      marginTop: theme.spacing(4),
    },
  })
)

export interface LoginOwnProps {}

export type LoginProps = LoginOwnProps

const Login = (props: LoginProps) => {
  const updateAuth = useContext(UpdateAuthContext)
  const alert = useAlert()
  const isAuthenticated = useIsAuthenticated()
  const { push } = useHistory()

  useEffectOnce(() => {
    ;(async () => {
      if (isAuthenticated) {
        push(routes.app.home)
        return
      }

      const authResponse = await firebase.auth().getRedirectResult()
      if (authResponse.credential) {
        updateAuth(authResponse as any)
        push(routes.app.home)
      } else {
        const provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope('email')
        provider.addScope('profile')
        provider.addScope('openid')
        provider.addScope('https://www.googleapis.com/auth/analytics.readonly')

        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        firebase.auth().signInWithRedirect(provider)
      }
    })()
      .then(() => {})
      .catch((e) => {
        alert('error', 'An error occurred while logging into google')
        console.error(e)
      })
  })

  if (isAuthenticated) return <Redirect to={routes.app.home} />

  return <LoadingView />
}

export default React.memo(Login)
