import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { useData } from '../../../Contexts/DataProvider/useDataAggregation'
import { Dayjs } from 'dayjs'
import {
  calculateBreakdown,
  ISOWeekBreakdown,
  ISODayBreakdown,
  ISOAverage,
} from './util'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import Container from './Container'
import useFormatValue from '../../../Hooks/useFormatValue'
import { colors } from '../../../theme'
import clsx from 'clsx'
import { Link } from '@material-ui/core'
import { Cascader } from 'antd'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    bolded: {
      fontWeight: 'bolder',
    },
    avgRow: {},
  })
)

export interface WeekTotalTableOwnProps {
  focusedDay: Dayjs
  className?: string
}

export type WeekTotalTableProps = WeekTotalTableOwnProps

const options = [
  {
    value: 1,
    label: 'Monday',
  },
  {
    value: 2,
    label: 'Tuesday',
  },
  {
    value: 3,
    label: 'Wednesday',
  },
  {
    value: 4,
    label: 'Thursday',
  },
  {
    value: 5,
    label: 'Friday',
  },
  {
    value: 6,
    label: 'Saturday',
  },
  {
    value: 7,
    label: 'Sunday',
  },
]

const WeekTotalTable = (props: WeekTotalTableProps) => {
  const { data } = useData()
  const [focusedDayOfWeek, setFocusedDayOfWeek] = useState<number>(
    props.focusedDay?.isoWeekday?.() || 1
  )
  const dayOfWeekText =
    options.find((_) => _.value === focusedDayOfWeek)?.label || 'Sunday'
  useEffect(() => {
    setFocusedDayOfWeek(props.focusedDay?.isoWeekday?.())
  }, [props.focusedDay, setFocusedDayOfWeek])
  const breakdown = useMemo(() => calculateBreakdown(props.focusedDay, data), [
    props.focusedDay,
    data,
  ])
  const classes = useStyles()
  const formatValue = useFormatValue()
  const currentStyle: React.CSSProperties = {
    color: colors.contrast,
    fontWeight: 'bolder',
  }

  const getTargetDay = (week: ISOWeekBreakdown) => {
    return week.days.find(
      (_) => _.isoDayOfWeek === focusedDayOfWeek
    ) as ISODayBreakdown
  }

  const dayAvg = breakdown.dayAvg.find(
    (_) => _.isoDayOfWeek === focusedDayOfWeek
  ) as ISOAverage

  return (
    <Container
      title="ISO Week Performance"
      className={clsx(classes.root, props.className)}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.bolded}>{'ISO Week'}</TableCell>
            <TableCell className={classes.bolded} align="right">
              <Cascader
                options={options}
                onChange={(_) => setFocusedDayOfWeek(_[0] as number)}
              >
                <Link href="#">{dayOfWeekText}</Link>
              </Cascader>
            </TableCell>
            <TableCell className={classes.bolded} align="right">
              {'Total'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {breakdown.weeks.map((week) => (
            <TableRow key={week.isoWeek}>
              <TableCell className={classes.bolded}>
                {week.isCurrent ? 'Current' : week.isoWeek}
              </TableCell>
              <TableCell
                align="right"
                style={getTargetDay(week).isCurrent ? currentStyle : {}}
              >
                {formatValue(getTargetDay(week).value)}
              </TableCell>
              <TableCell
                align="right"
                key={week.isoWeek}
                style={week.isCurrent ? currentStyle : {}}
              >
                {formatValue(week.total)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={classes.avgRow}>
            <TableCell className={classes.bolded}>{'Average'}</TableCell>
            <TableCell align="right" className={classes.bolded}>
              {formatValue(dayAvg.avgValue)}
            </TableCell>
            <TableCell align="right" className={classes.bolded}>
              {formatValue(breakdown.avg)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  )
}

export default React.memo(WeekTotalTable)
