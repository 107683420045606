import { useMemo, useState, useCallback } from 'react'

const getInitalItem = <TStorageItem>(
  storageKey: string,
  defaultValue: TStorageItem
): TStorageItem => {
  const storageItem = window.localStorage.getItem(storageKey)
  if (storageItem) {
    return JSON.parse(storageItem) as TStorageItem
  } else {
    return defaultValue
  }
}

export default function useLocalStorageState<TStorageItem>(
  storageKey: string,
  defaultValue: TStorageItem
): [TStorageItem, (newValue: TStorageItem) => void] {
  const key = `@@swellstats/localStorageState/${storageKey}`
  const initialItem = useMemo(() => getInitalItem(key, defaultValue), [])
  const [inMemoryItem, setInMemoryItem] = useState<TStorageItem>(initialItem)

  const interceptSetState = useCallback(
    (newState: TStorageItem) => {
      window.localStorage.setItem(key, JSON.stringify(newState))
      setInMemoryItem(newState)
    },
    [setInMemoryItem]
  )

  return [inMemoryItem, interceptSetState]
}
