import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Select } from 'antd'
import { Typography } from '@material-ui/core'
import { useDataBounds } from '../../Contexts/DataProvider/useDataAggregation'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generateCalendar from 'antd/es/calendar/generateCalendar'
import 'antd/es/calendar/style'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
    selects: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export interface CalendarPickerOwnProps {
  value: Dayjs
  onChange: (updated: Dayjs) => void
  onPanelChange?: (updated: Dayjs) => void
  suppressHeaderMessage?: boolean
}

export type CalendarPickerProps = CalendarPickerOwnProps

const CalendarPicker = (props: CalendarPickerProps) => {
  const classes = useStyles()
  const [rangeStart, rangeEnd] = useDataBounds()
  return (
    <Calendar
      fullscreen={false}
      value={props.value as any}
      onChange={props.onChange as any}
      validRange={[rangeStart.startOf('day'), rangeEnd.endOf('day')]}
      onPanelChange={props.onPanelChange}
      headerRender={({ value, type, onChange, onTypeChange }) => {
        const start = 0
        const end = 12
        const monthOptions = []

        const current = value
        const localeData = value.localeData()
        const months = []
        for (let i = 0; i < 12; i++) {
          current.month(i)
          months.push(localeData.monthsShort(current))
        }

        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option
              value={index}
              className="month-item"
              key={`${index}`}
            >
              {months[index]}
            </Select.Option>
          )
        }
        const month = value.month()

        const year = value.year()
        const options = []
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          )
        }
        return (
          <div className={classes.header}>
            {props.suppressHeaderMessage ? (
              <div />
            ) : (
              <Typography variant="h6">{'Focus Date'}</Typography>
            )}
            <div className={classes.selects}>
              <Select
                size="middle"
                dropdownMatchSelectWidth={false}
                value={month}
                onChange={(selectedMonth) => {
                  const newValue = value
                  newValue.month(selectedMonth)
                  onChange(newValue)
                }}
              >
                {monthOptions}
              </Select>
              <Select
                size="middle"
                dropdownMatchSelectWidth={false}
                className="my-year-select"
                onChange={(newYear) => {
                  const now = value.year(Number(newYear))
                  onChange(now)
                }}
                value={String(year)}
              >
                {options}
              </Select>
            </div>
          </div>
        )
      }}
    />
  )
}

export default React.memo(CalendarPicker)
