import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Modal, Typography, IconButton, Drawer } from '@material-ui/core'
import Paper from './Paper'
import { X, ChevronDown } from 'react-feather'
import Backdrop from '@material-ui/core/Backdrop'
import clsx from 'clsx'
import useIsMobile from '../Hooks/useIsMobile'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    drawerHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    },
    modalTitle: {
      fontWeight: 'bold',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPopover: {
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
    },
    drawerPopover: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '10px 10px 0px 0px',
    },
  })
)

export interface PromptOwnProps {
  title: string
  isOpen: boolean
  onDismiss?: () => void
  children: any
  modalClassName?: string
  drawerClassName?: string
  dismissDisabled?: boolean
}

export type PromptProps = PromptOwnProps

const Prompt = (props: PromptProps) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  if (!isMobile) {
    return (
      <Modal
        className={classes.modal}
        open={props.isOpen}
        onClose={props.onDismiss}
        BackdropComponent={Backdrop}
      >
        <Paper className={clsx(classes.modalPopover, props.modalClassName)}>
          <div className={classes.modalHeader}>
            <Typography variant="h5" className={classes.modalTitle}>
              {props.title}
            </Typography>
            {!props.dismissDisabled && (
              <IconButton size="small" onClick={props.onDismiss}>
                <X />
              </IconButton>
            )}
          </div>
          {props.children}
        </Paper>
      </Modal>
    )
  } else {
    return (
      <Drawer
        anchor="bottom"
        open={props.isOpen}
        onClose={props.onDismiss}
        classes={{ paper: clsx(classes.drawerPopover, props.drawerClassName) }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h5" className={classes.modalTitle}>
            {props.title}
          </Typography>
          <IconButton size="small">
            <ChevronDown onClick={props.onDismiss} />
          </IconButton>
        </div>
        {props.children}
      </Drawer>
    )
  }
}

export default React.memo(Prompt)
