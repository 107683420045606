import { useContext, useCallback } from 'react'
import { DataQueryValuesContext } from '../Contexts/DataProvider'
import { formatNumeric } from '../util/formatValue'

export const conditionallyRound = (number: number) =>
  Math.round((number || 0) * 1e2) / 1e2

const useFormatValue = () => {
  const metric = useContext(DataQueryValuesContext)?.metric
  return useCallback(
    (value: number) =>
      formatNumeric(conditionallyRound(value), metric === 'revenue'),
    [metric]
  )
}

export default useFormatValue
