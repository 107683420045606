import React from 'react'
import { UpdateAuthContext, AuthContext, Auth } from './Contexts/AuthContext'

export interface RootContextOwnProps {
  children: any
  authState: Auth
  setAuthState: (auth: Auth) => void
}

export type RootContextProps = RootContextOwnProps

const RootContext = ({
  children,
  authState,
  setAuthState,
}: RootContextProps) => {
  return (
    <UpdateAuthContext.Provider value={setAuthState}>
      <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
    </UpdateAuthContext.Provider>
  )
}

export default React.memo(RootContext)
