import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useData } from '../Contexts/DataProvider/useDataAggregation'
import GraphLoader from './GraphLoader'
import { colors } from '../theme'

export const useStyles = makeStyles((theme: Theme) => createStyles({}))

export type GraphDimensions = { width: number; height: number }

export interface GraphShellOwnProps {
  renderGraph: (dimensions: GraphDimensions) => any
  renderLoader?: boolean
  className?: string
}

export type GraphShellProps = GraphShellOwnProps

export type GraphResolution = 'hour' | 'day' | 'month' | 'week'

const GraphShell = (props: GraphShellProps) => {
  const { isLoading } = useData()

  const content = (
    <AutoSizer>
      {({ width, height }) => props.renderGraph({ width, height })}
    </AutoSizer>
  )

  return props.renderLoader ? (
    <GraphLoader isLoading={isLoading}>{content}</GraphLoader>
  ) : (
    <div className={props.className}>{content}</div>
  )
}

export default React.memo(GraphShell)
