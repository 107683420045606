import { Span } from '../Contexts/DataProvider/AnalyticsData/Selections'
import { WindowConfig } from './windowingUtils'

type CompareCascadeRootOption = {
  value: Span
  label: string
  children: CompareCascadeCompOption[]
}

type CompareCascadeCompOption = {
  value: Span
  label: string
}

export type ComparisonValue = [Span, Span]

export const options: CompareCascadeRootOption[] = [
  {
    value: 'day',
    label: 'Compare today',
    children: [
      {
        value: 'day',
        label: 'to yesterday',
      },
      {
        value: 'week',
        label: 'to this day last week',
      },
      {
        value: 'month',
        label: 'to this day last month',
      },
      {
        value: 'year',
        label: 'to this day last year',
      },
    ],
  },
  {
    value: 'week',
    label: 'Compare this week',
    children: [
      {
        value: 'week',
        label: 'last week',
      },
      {
        value: 'month',
        label: 'to this week last month',
      },
      {
        value: 'year',
        label: 'to this week last year',
      },
    ],
  },
  {
    value: 'month',
    label: 'Compare this month',
    children: [
      {
        value: 'month',
        label: 'last month',
      },
      {
        value: 'year',
        label: 'this month last year',
      },
    ],
  },
  {
    value: 'year',
    label: 'Compare this year',
    children: [
      {
        value: 'year',
        label: 'last year',
      },
    ],
  },
]

export const getShortText = (value: ComparisonValue): [string, string] => {
  const labels = {
    day: {
      day: ['today', 'yesterday'],
      week: ['today', 'this day last week'],
      month: ['today', 'this day last month'],
      year: ['today', 'this day last year'],
    },
    week: {
      day: ['', ''],
      week: ['this week', 'last week'],
      month: ['this week', 'this week last month'],
      year: ['this week', 'this week last year'],
    },
    month: {
      day: ['', ''],
      week: ['', ''],
      month: ['this month', 'last month'],
      year: ['this month', 'this month last year'],
    },
    year: {
      day: ['', ''],
      week: ['', ''],
      month: ['', ''],
      year: ['this year', 'last year'],
    },
  }

  return labels[value[0]][value[1]] as [string, string]
}

export const valueToWindowsConfig = (
  value: ComparisonValue
): WindowConfig[] => {
  return [
    {
      span: value[0],
      aggregation: 'last',
      frame: value[0],
      rangeSpan: value[0],
      rangeIterations: 0,
    },
    {
      span: value[0],
      aggregation: 'last',
      frame: value[1],
      rangeSpan: value[1],
      rangeIterations: 1,
    },
  ]
}
