import React, { SVGProps } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography, useTheme, fade } from '@material-ui/core'
import { motion } from 'framer-motion'
import { useLocation, useHistory } from 'react-router-dom'
import { Card } from 'antd'
import { colors } from '../../theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      paddingBottom: theme.spacing(0.5),
      width: 100,
      backgroundColor: theme.palette.common.white,
    },
    selectBar: {
      width: '100%',
      height: 6,
      marginBottom: theme.spacing(0.7),
    },
    icon: {
      marginBottom: theme.spacing(0.5),
    },
  })
)

export interface MenuItemOwnProps {
  icon: any
  text: string
  route: string
}

export type MenuItemProps = MenuItemOwnProps

const MenuItem = (props: MenuItemProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const { push } = useHistory()

  const variants = {
    hover: {
      color: colors.opposite,
      stroke: colors.opposite,
    },
    unselected: {
      color: theme.palette.text.secondary,
      stroke: theme.palette.text.secondary,
    },
    selected: {
      color: colors.opposite,
      stroke: colors.opposite,
    },
  }

  const topBarVariants = {
    hover: {
      backgroundColor: colors.opposite,
    },
    unselected: {
      backgroundColor: fade(colors.opposite, 0),
    },
    selected: {
      backgroundColor: colors.opposite,
    },
  }

  return (
    <motion.div
      whileHover="hover"
      variants={variants}
      className={classes.root}
      animate={props.route === location.pathname ? 'selected' : 'unselected'}
      onClick={() => push(props.route)}
      transition={{ duration: 0.7 }}
    >
      <motion.div
        className={classes.selectBar}
        variants={topBarVariants}
      ></motion.div>
      <props.icon color="inherit" className={classes.icon} />
      <Typography color="inherit" variant="caption">
        {props.text}
      </Typography>
    </motion.div>
  )
}

export default React.memo(MenuItem)
