import { Theme, createMuiTheme } from '@material-ui/core'

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#4576d1',
    },
    secondary: {
      main: '#45d1bb',
    },
    background: {
      paper: '#FEFEFE',
      default: '#F1F5F7',
      darkPaper: '#d9d9d9',
    } as any,
    text: {
      whiteSecondary: '#D5D5D5',
    } as any,
  },
  props: {
    MuiAppBar: {},
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        borderRadius: 0,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Lato', sans-serif",
  },
})

export default customTheme

export const colors = {
  primary: '#4576d1',
  primaryDark: '#305292',
  contrast: '#6a4cd1',
  opposite: '#45d1bb',
  oppositeDark: '#309283',
  darkGray: '#888888',
}

export const boxShadows = {
  1: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
}
