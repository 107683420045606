import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import ProgressBar from './ProgressBar'
import { Typography, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import useFormatValue from '../../../Hooks/useFormatValue'
import { Flag, Award } from 'react-feather'
import { useData } from '../../../Contexts/DataProvider/useDataAggregation'
import Card from '../Card'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressBar: {
      width: '100%',
    },
    subHeader: {
      fontWeight: 700,
      textTransform: 'uppercase',
      marginRight: theme.spacing(1),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    text: {
      color: theme.palette.text.secondary,
    },
    progressHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      width: 12,
      height: 12,
    },
    progressFraction: {
      marginRight: theme.spacing(0.5),
    },
    progress: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1.5),
    },
  })
)

export interface CardTemplateOwnProps {
  title: string
  numerator: number
  denominator: number
  className?: string
}

export type CardTemplateProps = CardTemplateOwnProps

const CardTemplate = (props: CardTemplateProps) => {
  const classes = useStyles()
  const formatValue = useFormatValue()
  const { isLoading } = useData()
  const theme = useTheme()

  const Icon = isLoading || props.numerator < props.denominator ? Flag : Award

  return (
    <Card
      header={
        <div className={classes.header}>
          <Typography variant="body1" className={classes.text}>
            {props.title}
          </Typography>
          <div className={classes.progressHeader}>
            <Typography
              variant="caption"
              className={clsx(classes.text, classes.progressFraction)}
            >{`${formatValue(props.numerator)} / ${formatValue(
              props.denominator
            )}`}</Typography>
            <Icon size={12} color={theme.palette.text.secondary} />
          </div>
        </div>
      }
      content={
        <ProgressBar
          className={classes.progress}
          progress={props.numerator || null}
          target={props.denominator || null}
        />
      }
      className={props.className}
    />
  )
}

export default React.memo(CardTemplate)
