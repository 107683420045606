import _noop from 'lodash/noop'
import { createContext, useContext, useMemo } from 'react'
import UserAccounts, {
  defaultViewSelectValue,
  ViewSelectValue,
} from './UserAccounts'
import useLocalStorageState from '../../Hooks/useLocalStorageState'

export const UserAccountsContext = createContext<UserAccounts>(
  UserAccounts.initNew()
)

export const UpdateUserAccountsContext = createContext<
  (updatedUserAccounts: UserAccounts) => void
>(_noop)

export const usePersistedViewSelectValue = () =>
  useLocalStorageState('ViewSelectPromptValues-values', defaultViewSelectValue)

export const useUpdateSelectedView = () => {
  const userAccounts = useContext(UserAccountsContext)
  const updateUserAccountsContext = useContext(UpdateUserAccountsContext)
  const [, setPersistedValues] = usePersistedViewSelectValue()

  return (newValue: ViewSelectValue) => {
    setPersistedValues(newValue)
    updateUserAccountsContext(userAccounts.setSelectedView(newValue))
  }
}
