import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  useIsLoadingSubscriptionStatus,
  useDoesUserHaveActiveSubscription,
} from '../../Hooks/useSubscriptionStatus'
import AccountSection from './AccountSection'
import { Typography, Button, Box } from '@material-ui/core'
import { CreditCard } from 'react-feather'
import useManageSubscription from './useManageSubscription'
import AntIconButton from '../../SharedComponents/AntIconButton'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
    contentContainer: {
      width: '100%',
    },
  })
)

export interface ManageSubscriptionSectionOwnProps {
  className?: string
}

export type ManageSubscriptionSectionProps = ManageSubscriptionSectionOwnProps

const ManageSubscriptionSection = (props: ManageSubscriptionSectionProps) => {
  const classes = useStyles()
  const isLoadingSubscriptionStatus = useIsLoadingSubscriptionStatus()
  const userHasActiveSubscription = useDoesUserHaveActiveSubscription()
  const { isLoading, onManageSubscription } = useManageSubscription()

  if (isLoadingSubscriptionStatus || !userHasActiveSubscription) return null

  return (
    <AccountSection
      label="You are Subscribed to Swell Stats"
      contentClassName={classes.contentContainer}
      className={props.className}
    >
      <Box width="100%" marginBottom={2}>
        <Typography variant="body1">
          {
            'Click the button below to manage your Swell Stats subscription and payment history'
          }
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <AntIconButton
          Icon={CreditCard}
          size="large"
          type="primary"
          onClick={onManageSubscription}
          loading={isLoading}
        >
          {`Manage Subscription`}
        </AntIconButton>
      </Box>
    </AccountSection>
  )
}

export default React.memo(ManageSubscriptionSection)
