import React, { useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { WindowConfig } from '../../../util/windowingUtils'
import useTrackWindowedProgress from '../useTrackWindowedProgress'
import { ProgressConfig } from './types'
import CardTemplate from './CardTemplate'

export const useStyles = makeStyles((theme: Theme) => createStyles({}))

export interface ProgressDisplayOwnProps {
  config: ProgressConfig
  className?: string
}

export type ProgressDisplayProps = ProgressDisplayOwnProps

const ProgressDisplay = (props: ProgressDisplayProps) => {
  const trackWindow = useMemo(() => {
    // the input window will be the reference, get the "current" version of that window
    return {
      ...props.config.windowConfig,
      frame: props.config.windowConfig.span,
      rangeSpan: props.config.windowConfig.span,
      rangeIterations: 0,
    } as WindowConfig
  }, [props.config.windowConfig])
  const goalProgress = useTrackWindowedProgress(
    trackWindow,
    props.config.windowConfig,
    {
      forceRelativeFrame: false,
    }
  )

  return (
    <CardTemplate
      title={props.config.title}
      numerator={goalProgress.trackValue}
      denominator={goalProgress.refValue}
      className={props.className}
    />
  )
}

export default React.memo(ProgressDisplay)
