import { useState, useCallback } from 'react'

export default function useToggle(
  defaultValue: boolean
): [boolean, () => void, () => void] {
  const [toggleValue, setToggleValue] = useState(defaultValue)

  return [
    toggleValue,
    useCallback(() => {
      setToggleValue(true)
    }, [setToggleValue]),
    useCallback(() => {
      setToggleValue(false)
    }, [setToggleValue]),
  ]
}
