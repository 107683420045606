import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Progress from '../../../SharedComponents/Widgets/Progress'
import CalloutCard from '../../../SharedComponents/Widgets/CalloutCard'
import HeaderMessage from '../HeaderMessage'
import { motion, Variants } from 'framer-motion'
import Graph from '../Graph'
import FreeTrialWarning from '../../../SharedComponents/FreeTrialWarning'
import SubscriptionGaurd from '../../../SharedComponents/SubscriptionGaurd'
import SwitchMetricPrompt from '../../../SharedComponents/SwitchMetricPrompt'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'auto',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      width: '100%',
    },
    content: {
      width: '100%',
      padding: theme.spacing(1),
    },
    statsContainer: {
      display: 'flex',
      minWidth: 0,
      flex: 1,
      height: '100%',
    },
    cardContainer: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    card: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: 320,
    },
    graphContainer: {
      flex: 1,
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    graph: {
      height: 620,
    },
    freeTrialAlert: {
      marginBottom: theme.spacing(4),
    },
    switchMetricPrompt: {
      marginBottom: theme.spacing(4),
    },
  })
)

export interface HomeOwnProps {}

export type HomeProps = HomeOwnProps
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
    },
  },
} as Variants

const fadeIn = (element: any) => (
  <motion.div variants={item}>{element}</motion.div>
)
const Home = (props: HomeProps) => {
  const classes = useStyles()

  return (
    <SubscriptionGaurd>
      <div className={classes.root}>
        <div className={classes.header}>
          <HeaderMessage variant="h5" />
        </div>
        <div className={classes.content}>
          <FreeTrialWarning className={classes.freeTrialAlert} />
          <SwitchMetricPrompt className={classes.switchMetricPrompt} />
          <motion.div
            className={classes.cardContainer}
            initial="hidden"
            animate="show"
            variants={container}
          >
            {fadeIn(
              <CalloutCard
                className={classes.card}
                config={{
                  title: 'Today',
                  windowConfig: {
                    span: 'day',
                    aggregation: 'last',
                    frame: 'day',
                    rangeSpan: 'day',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.card}
                config={{
                  title: "Today's Goal",
                  windowConfig: {
                    span: 'day',
                    aggregation: 'last',
                    frame: 'day',
                    rangeSpan: 'day',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.card}
                config={{
                  title: 'This Week',
                  windowConfig: {
                    span: 'week',
                    aggregation: 'last',
                    frame: 'week',
                    rangeSpan: 'week',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.card}
                config={{
                  title: "Week's Goal",
                  windowConfig: {
                    span: 'week',
                    aggregation: 'last',
                    frame: 'week',
                    rangeSpan: 'week',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.card}
                config={{
                  title: 'This Month',
                  windowConfig: {
                    span: 'month',
                    aggregation: 'last',
                    frame: 'month',
                    rangeSpan: 'month',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.card}
                config={{
                  title: "Months's Goal",
                  windowConfig: {
                    span: 'month',
                    aggregation: 'last',
                    frame: 'month',
                    rangeSpan: 'month',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.card}
                config={{
                  title: 'This Year',
                  windowConfig: {
                    span: 'year',
                    aggregation: 'last',
                    frame: 'year',
                    rangeSpan: 'year',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.card}
                config={{
                  title: "Year's Goal",
                  windowConfig: {
                    span: 'year',
                    aggregation: 'last',
                    frame: 'year',
                    rangeSpan: 'year',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
          </motion.div>
          <div className={classes.statsContainer}>
            <Graph className={classes.graph} />
          </div>
        </div>
      </div>
    </SubscriptionGaurd>
  )
}

export default React.memo(Home)
