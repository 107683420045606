import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    label: {
      color: theme.palette.text.secondary,
    },
  })
)

export interface LabeledSectionOwnProps {
  className?: string
  label: string
  children: any
}

export type LabeledSectionProps = LabeledSectionOwnProps

const LabeledSection = (props: LabeledSectionProps) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, props.className)}>
      {props.children}
      <Typography variant="body1" className={classes.label}>
        {props.label}
      </Typography>
    </div>
  )
}

export default React.memo(LabeledSection)
