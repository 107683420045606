import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  useIsTrialExpired,
  useIsLoadingSubscriptionStatus,
} from '../Hooks/useSubscriptionStatus'
import { Box, Typography, Fade, CircularProgress } from '@material-ui/core'
import AntIconButton from './AntIconButton'
import { CreditCard } from 'react-feather'
import useAddSubscription from '../Pages/Account/useAddSubscription'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerText: {
      marginBottom: theme.spacing(4),
      fontWeight: 'bold',
      textAlign: 'center',
    },
    subText: {
      marginBottom: theme.spacing(6),
      textAlign: 'center',
    },
    loadingText: {
      marginTop: theme.spacing(6),
      fontWeight: 'bold',
    },
  })
)

export interface SubscriptionGaurdOwnProps {
  children: any
}

export type SubscriptionGaurdProps = SubscriptionGaurdOwnProps

const SubscriptionGaurd = (props: SubscriptionGaurdProps) => {
  const classes = useStyles()
  const trialHasExpired = useIsTrialExpired()
  const isLoadingSubscriptionData = useIsLoadingSubscriptionStatus()
  const { isLoading, onAddSubscription } = useAddSubscription()

  if (!trialHasExpired && !isLoadingSubscriptionData) return props.children

  const renderExpiredContent = () => (
    <Fade in>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h3" className={classes.headerText}>
          {'Your Trial Has Expired'}
        </Typography>
        <Typography variant="h6" className={classes.subText}>
          {
            'To continue using Swell Stats, you must subscribe for only $5 a month'
          }
        </Typography>
        <AntIconButton
          Icon={CreditCard}
          onClick={onAddSubscription}
          type="primary"
          size="large"
          loading={isLoading}
        >
          {'Subscribe'}
        </AntIconButton>
      </Box>
    </Fade>
  )

  const renderLoader = () => (
    <Fade in>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} />
        <Typography variant="h6" className={classes.loadingText}>
          {'Loading Subscription Data...'}
        </Typography>
      </Box>
    </Fade>
  )

  return (
    <Box className={classes.root}>
      {isLoadingSubscriptionData ? renderLoader() : renderExpiredContent()}
    </Box>
  )
}

export default React.memo(SubscriptionGaurd)
