import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { motion } from 'framer-motion'

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
    },
  },
}

const duration = 5

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundSize: '400% 400%',
      backgroundColor: theme.palette.background.default,
      background: `linear-gradient(-45deg, #45d1bb, #6a4cd1)`,
      animation: 'gradient 3s ease infinite',
    },
    '@keyframes gradient': {
      '0%': {
        backgroundPosition: '0% 50%',
      },
      '50%': {
        backgroundPosition: '100% 50%',
      },
      '100%': {
        backgroundPosition: '0% 50%',
      },
    },
    disableAnimation: {
      animation: 'none',
    },
  })
)

export interface HazeBackgroundOwnProps {
  className?: string
  children: any
  disableAnimation?: boolean
}

export type HazeBackgroundProps = HazeBackgroundOwnProps

const HazeBackground = (props: HazeBackgroundProps) => {
  const classes = useStyles({})

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className={clsx(
        classes.root,
        props.disableAnimation && classes.disableAnimation,
        props.className
      )}
    >
      {props.children}
    </motion.div>
  )
}

export default React.memo(HazeBackground)
