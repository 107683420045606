import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import useDataBoundCountUp from '../../../Hooks/useDataBoundCountUp'
import PercentDisplay from './PercentDisplay'
import Card from '../Card'
import useFormatValue from '../../../Hooks/useFormatValue'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    text: {
      color: theme.palette.text.secondary,
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: theme.spacing(1.5),
    },
    value: {
      marginRight: theme.spacing(1.5),
      fontWeight: 'bolder',
      width: 160,
    },
  })
)

export type Progress = {
  numerator: number
  denominator: number
}

export interface CardTemplateOwnProps {
  title: string
  callOutValue: number
  className?: string
  progress?: Progress
}

export type CardTemplateProps = CardTemplateOwnProps

const CardTemplate = (props: CardTemplateProps) => {
  const classes = useStyles()
  const formatValue = useFormatValue()

  const countUpCalloutValue = useDataBoundCountUp(props.callOutValue)
  return (
    <Card
      header={
        <div className={classes.header}>
          <Typography variant="body1" className={classes.text}>
            {props.title}
          </Typography>
        </div>
      }
      content={
        <div className={classes.content}>
          <Typography variant="h4" className={classes.value}>
            {formatValue(countUpCalloutValue as number)}
          </Typography>
          {props.progress && (
            <PercentDisplay
              trackValue={props.progress.numerator}
              refValue={props.progress.denominator}
            />
          )}
        </div>
      }
      className={props.className}
    />
  )
}

export default React.memo(CardTemplate)
