import firebase from 'firebase/app'
import 'firebase/auth'
import { useContext } from 'react'
import { UpdateAuthContext } from '../Contexts/AuthContext'

function useLogout() {
  const updateAuth = useContext(UpdateAuthContext)
  return () => {
    updateAuth(null)
    firebase.auth().signOut()
  }
}

export default useLogout
