import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Paper from '../../SharedComponents/Paper'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    headerRow: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    label: {
      fontWeight: 'bold',
    },
    header: {
      marginBottom: theme.spacing(2),
    },
  })
)

export interface AccountSectionOwnProps {
  label: string
  className?: string
  contentClassName?: string
  children: any
  headerContent?: any
}

export type AccountSectionProps = AccountSectionOwnProps

const AccountSection = (props: AccountSectionProps) => {
  const classes = useStyles()

  return (
    <Paper className={clsx(classes.root, props.className)}>
      <div className={classes.headerRow}>
        <Typography variant="h6" className={classes.label}>
          {props.label}
        </Typography>
        {props.headerContent || null}
      </div>
      <div className={props.contentClassName}>{props.children}</div>
    </Paper>
  )
}

export default React.memo(AccountSection)
