import React from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import { useData } from '../../../Contexts/DataProvider/useDataAggregation'
import useDataBoundCountUp from '../../../Hooks/useDataBoundCountUp'
import { motion } from 'framer-motion'
import { Typography } from '@material-ui/core'
import { conditionallyRound } from '../../../Hooks/useFormatValue'
import { ArrowRight } from 'react-feather'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export interface PercentDisplayOwnProps {
  trackValue: number
  refValue: number
}

export type PercentDisplayProps = PercentDisplayOwnProps

const PercentDisplay = ({ refValue, trackValue }: PercentDisplayProps) => {
  const classes = useStyles()
  const diff = trackValue - refValue
  const isPositiveGrowth = diff >= 0
  const percentChange = (Math.abs(diff) / (refValue || 0.00001)) * 100
  const capped = Math.min(percentChange, 1000)
  const countUpPercentChanged = useDataBoundCountUp(capped)
  const theme = useTheme()

  const variants = {
    hidden: {
      opacity: 0,
      color: theme.palette.text.secondary,
    },
    growth: {
      opacity: 1,
      color: theme.palette.success.main,
    },
    decline: {
      opacity: 1,
      color: theme.palette.error.main,
    },
  }

  const iconVariants = {
    hidden: {
      rotate: 0,
    },
    growth: {
      rotate: -90,
    },
    decline: {
      rotate: 90,
    },
  }

  let animate = 'hidden'
  if (trackValue) {
    animate = isPositiveGrowth ? 'growth' : 'decline'
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate={animate}
      className={classes.root}
      transition={{ duration: 0.7 }}
    >
      <Typography variant="body1">{`${conditionallyRound(
        countUpPercentChanged
      )} %`}</Typography>
      <motion.div variants={iconVariants} transition={{ duration: 0.7 }}>
        <ArrowRight size={18} />
      </motion.div>
    </motion.div>
  )
}

export default React.memo(PercentDisplay)
