import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import useHasNoData from '../Hooks/useHasNoData'
import { createGlobalState } from 'react-use'
import Paper from './Paper'
import clsx from 'clsx'
import {
  Box,
  Typography,
  Icon,
  IconButton,
  Collapse,
  Fade,
} from '@material-ui/core'
import { AlertCircle, X } from 'react-feather'
import { useFilterLabel } from './GlobalFilterButton'
import { UpdateDataQueryValuesContext } from '../Contexts/DataProvider'
import { Metric } from '../util/queryUtil'
import { Button } from 'antd'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2),
    },
    header: {
      fontWeight: 'bold',
    },
    option: {
      marginTop: theme.spacing(2),
    },
  })
)

export interface SwitchMetricPromptOwnProps {
  className?: string
}

export type SwitchMetricPromptProps = SwitchMetricPromptOwnProps

const useGlobalMetricPromptDismissed = createGlobalState<boolean>(false)

const SwitchMetricPrompt = (props: SwitchMetricPromptProps) => {
  const classes = useStyles()
  const hasNoData = useHasNoData()
  const updateDataQueryValues = useContext(UpdateDataQueryValuesContext)
  const [
    globalMetricPromptDismissed,
    setGlobalMetricPromptDismissed,
  ] = useGlobalMetricPromptDismissed()

  const isIn = hasNoData && !globalMetricPromptDismissed

  const getUpdateHandler = (metric: Metric) => () => {
    updateDataQueryValues({
      metric,
      channel: 'all',
    })
  }

  return (
    <Collapse in={isIn}>
      <Fade in={isIn}>
        <Paper className={clsx(classes.root, props.className)}>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
          >
            <Box display="flex" alignItems="center">
              <Box marginRight={2}>
                <AlertCircle size={20} />
              </Box>
              <Typography variant="h6">
                {"Hmmm... there doesn't seem to be any data here"}
              </Typography>
            </Box>
            <IconButton onClick={() => setGlobalMetricPromptDismissed(true)}>
              <X />
            </IconButton>
          </Box>
          <Typography variant="body1">
            {
              'We might want to adjust the type of data SwellStats collects.  You can always update the collected by clicking the filter button above.  Or you can choose the option that best describes your data source.'
            }
          </Typography>
          <Box marginTop={3}>
            <Typography variant="body1">{'I am trying to track...'}</Typography>
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Button
              className={classes.option}
              type="link"
              size="large"
              onClick={getUpdateHandler('revenue')}
            >
              {'The revenue my site generates'}
            </Button>
            <Button
              className={classes.option}
              type="link"
              size="large"
              onClick={getUpdateHandler('sessions')}
            >
              {'The number of users on my site'}
            </Button>
            <Button
              className={classes.option}
              type="link"
              size="large"
              onClick={getUpdateHandler('transactions')}
            >
              {'The amount of financial transactions that occur'}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Collapse>
  )
}

export default React.memo(SwitchMetricPrompt)
