import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { DataContext } from '../Contexts/DataProvider'
import { Collapse, LinearProgress } from '@material-ui/core'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 6,
    },
  })
)

export interface LoadingBannerOwnProps {}

export type LoadingBannerProps = LoadingBannerOwnProps

const LoadingBanner = (props: LoadingBannerProps) => {
  const classes = useStyles()
  const { isLoading } = useContext(DataContext)

  return (
    <Collapse in={isLoading}>
      <LinearProgress className={classes.root} />
    </Collapse>
  )
}

export default React.memo(LoadingBanner)
