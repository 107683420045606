import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  DataQueryValuesContext,
  UpdateDataQueryValuesContext,
} from '../Contexts/DataProvider'
import { Button, Radio } from 'antd'
import { Filter } from 'react-feather'
import useToggle from '../Hooks/useToggle'
import { useData } from '../Contexts/DataProvider/useDataAggregation'
import Prompt from './Prompt'
import { Typography, IconButton } from '@material-ui/core'
import { Metric, Channels } from '../util/queryUtil'
import useIsMobile from '../Hooks/useIsMobile'
import AntIconButton from './AntIconButton'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
    modal: {
      width: 600,
      height: 400,
    },
    modalContentContainer: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
    },
    toggleContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(3),
    },
    toggleLabel: {
      marginBottom: theme.spacing(1),
      fontWeight: 'bolder',
    },
  })
)

export interface GlobalFilterButtonOwnProps {}

export type GlobalFilterButtonProps = GlobalFilterButtonOwnProps

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const channelLabels = {
  all: 'All Channels',
  direct: 'Direct Traffic',
  organic: 'Organic Traffic',
  paid: 'Paid Traffic',
  email: 'Email',
  referral: 'Referrals',
  other: 'Other Traffic',
}

export function useFilterLabel() {
  const dataQueryValues = useContext(DataQueryValuesContext)
  return `${capitalize(dataQueryValues.metric)} Through ${
    channelLabels[dataQueryValues.channel]
  }`
}

const GlobalFilterButton = (props: GlobalFilterButtonProps) => {
  const [isOpen, onOpen, onClose] = useToggle(false)
  const isMobile = useIsMobile()
  const classes = useStyles({})
  const { isLoading } = useData()
  const dataQueryValues = useContext(DataQueryValuesContext)
  const updateDataQueryValues = useContext(UpdateDataQueryValuesContext)
  const filterLabel = useFilterLabel()

  const content = (
    <div className={classes.modalContentContainer}>
      <div className={classes.toggleContainer}>
        <Typography variant="body1" className={classes.toggleLabel}>
          {'Metric'}
        </Typography>
        <Radio.Group
          size="large"
          value={dataQueryValues.metric}
          buttonStyle="solid"
          onChange={(e) =>
            updateDataQueryValues({
              ...dataQueryValues,
              metric: e.target.value as Metric,
            })
          }
        >
          <Radio.Button value="revenue">{'Revenue'}</Radio.Button>
          <Radio.Button value="transactions">{'Transactions'}</Radio.Button>
          <Radio.Button value="sessions">{'Sessions'}</Radio.Button>
        </Radio.Group>
      </div>
      <div className={classes.toggleContainer}>
        <Typography variant="body1" className={classes.toggleLabel}>
          {'Source Channel'}
        </Typography>
        <Radio.Group
          size="large"
          value={dataQueryValues.channel}
          buttonStyle="solid"
          onChange={(e) =>
            updateDataQueryValues({
              ...dataQueryValues,
              channel: e.target.value as Channels | 'all',
            })
          }
        >
          {[
            'all',
            'direct',
            'organic',
            'paid',
            'email',
            'referral',
            'other',
          ].map((channel) => (
            <Radio.Button key={channel} value={channel}>
              {channelLabels[channel as Channels]}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </div>
  )

  const renderButton = () =>
    isMobile ? (
      <IconButton size="small" disabled={isLoading} onClick={onOpen}>
        <Filter />
      </IconButton>
    ) : (
      <AntIconButton
        className={classes.root}
        type="primary"
        Icon={Filter}
        shape="round"
        size="large"
        disabled={isLoading}
        onClick={onOpen}
      >
        {filterLabel}
      </AntIconButton>
    )

  return (
    <>
      {renderButton()}
      <Prompt
        title="Update Filters"
        isOpen={isOpen}
        onDismiss={onClose}
        modalClassName={classes.modal}
      >
        {content}
      </Prompt>
    </>
  )
}

export default React.memo(GlobalFilterButton)
