import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { UserAccountsContext } from '../AccountInitialization/Contexts'
import { Box, Typography, Link } from '@material-ui/core'
import { AlertCircle, LogOut } from 'react-feather'
import AntIconButton from '../AntIconButton'
import useLogout from '../../Hooks/useLogout'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface NoAccountGaurdOwnProps {
  children: any
}

export type NoAccountGaurdProps = NoAccountGaurdOwnProps

const NoAccountGaurd = (props: NoAccountGaurdProps) => {
  const userAccounts = useContext(UserAccountsContext)
  const logout = useLogout()

  if (!userAccounts.isInitialized) return props.children

  const allAccountsDisabled = !userAccounts.allAccounts.find(
    (_) => !userAccounts.isInvalidAccount(_?.id)
  )

  if (!allAccountsDisabled) return props.children

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      maxWidth={456}
      flexDirection="column"
      padding={2}
    >
      <Box display="flex">
        <Box marginRight={2}>
          <AlertCircle />
        </Box>
        <Typography variant="body1">
          {
            'You do not have any valid Google Analytics accounts that integrate with SwellStats.  SwellStats requires an analytics account with properties and views.  If you configure your analytics account to have a property and view, then you can use SwellStats.  You can read more about Google Analytics accounts, properties, and views '
          }
          <Link
            href="https://support.google.com/analytics/answer/1009618?hl=en"
            variant="inherit"
          >
            {'here.  '}
          </Link>
          {
            'If you think you logged into the wrong SwellStats account, you can switch accounts below.'
          }
        </Typography>
      </Box>
      <Box marginTop={4} width="100%" display="flex" justifyContent="center">
        <AntIconButton
          size="large"
          type="primary"
          Icon={LogOut}
          onClick={logout}
        >
          {'Switch Google Accounts'}
        </AntIconButton>
      </Box>
    </Box>
  )
}

export default React.memo(NoAccountGaurd)
