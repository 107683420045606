import React, { useState, useContext, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import UserAccounts from './UserAccounts'
import { AuthContext } from '../../Contexts/AuthContext'
import useAlertRequestTriggers from '../../Hooks/useAlertRequestTriggers'
import GoogleAPIService from '../../services/GoogleAPIService'
import { UserAccountsContext, UpdateUserAccountsContext } from './Contexts'
import LoadingView from '../LoadingView'
import ViewSelectPrompt from './ViewSelectPrompt'
import useAccessToken from '../../Hooks/useAccessToken'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface AccountInitializationOwnProps {
  children: any
}

export type AccountInitializationProps = AccountInitializationOwnProps

const AccountInitialization = (props: AccountInitializationProps) => {
  const auth = useContext(AuthContext)
  const alertRequestTriggers = useAlertRequestTriggers(
    null,
    'Error loading user accounts'
  )
  const [userAccounts, setUserAccounts] = useState<UserAccounts>(
    UserAccounts.initNew()
  )
  const accessToken = useAccessToken()

  useEffect(() => {
    if (!accessToken) return

    async function loadAccounts() {
      const googleAPIService = new GoogleAPIService(accessToken as string)
      const accounts = await googleAPIService.getAccountSummary(
        alertRequestTriggers
      )

      if (!accounts) return

      setUserAccounts(userAccounts.setLoadData(accounts))
    }

    loadAccounts()
  }, [accessToken])

  let content = null
  if (!accessToken || !!userAccounts.selectedView) {
    // will be redirected to login if no access token or everything is good and app can continue
    content = props.children
  } else if (!userAccounts.isInitialized) {
    // accounts still loading, app is stalled
    content = <LoadingView />
  } else {
    // need to select an account to continue
    content = <ViewSelectPrompt />
  }

  return (
    <UpdateUserAccountsContext.Provider value={setUserAccounts}>
      <UserAccountsContext.Provider value={userAccounts}>
        {content}
      </UserAccountsContext.Provider>
    </UpdateUserAccountsContext.Provider>
  )
}

export default React.memo(AccountInitialization)
