import { Dayjs } from 'dayjs'
import { Span } from '../Contexts/DataProvider/AnalyticsData/Selections'

export const getWeekOfMonth = (date: Dayjs) => {
  const firstDayOfMonth = date.startOf('month')
  const firstDayOfWeek = firstDayOfMonth.startOf('week')

  const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days')

  return Math.ceil((date.date() + offset) / 7)
}

export const getWeekOfYear = (date: Dayjs) => {
  const firstDayOfYear = date.startOf('year')
  const firstDayOfWeek = firstDayOfYear.startOf('week')

  const offset = firstDayOfYear.diff(firstDayOfWeek, 'days')

  return Math.ceil((date.date() + offset) / 7)
}

export const isSameOrAfterUtil = (d1: Dayjs, d2: Dayjs): boolean => {
  return d1.unix() >= d2.unix()
}

export const isSameOrBeforeUtil = (d1: Dayjs, d2: Dayjs): boolean => {
  return d1.unix() <= d2.unix()
}

export const isAfterUtil = (d1: Dayjs, d2: Dayjs): boolean => {
  return d1.unix() > d2.unix()
}

export const isBeforeUtil = (d1: Dayjs, d2: Dayjs): boolean => {
  return d1.unix() < d2.unix()
}

/**
 * The cache of the day base hour value
 */
const cachedDayBase: Record<string, number> = {}
const getDayBase = (date: Dayjs, span: Span) => {
  const asLocal = date.local()
  const cacheKey = `${asLocal.date()}-${asLocal.month()}-${asLocal.year()}-${span}`
  if (cachedDayBase[cacheKey] !== undefined) return cachedDayBase[cacheKey]

  let dayBase = 0
  if (span === 'week') {
    dayBase = asLocal.weekday()
  } else if (span === 'month') {
    dayBase = asLocal.date()
  } else if (span === 'year') {
    dayBase = asLocal.dayOfYear()
  }

  cachedDayBase[cacheKey] = dayBase
  return dayBase
}

/**
 * Gets the comparitive span hour value.  This enables comparison within a span.
 * Example: is Today of this month before the 12th of last month if comparing by month
 *
 * This will get the the hours since the start of each respective month
 *
 * It turns out this is a relatively dayjs
 * costly calculation for dayjs, so this will cache results to it's best ability
 */
export const getSpanHourValue = (date: Dayjs, span: Span) => {
  return getDayBase(date, span) * 24 + date.local().hour()
}
