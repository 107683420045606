import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Dayjs } from 'dayjs'
import useToggle from '../../../Hooks/useToggle'
import Prompt from '../../../SharedComponents/Prompt'
import CalendarPicker from '../CalendarPicker'
import { IconButton } from '@material-ui/core'
import { Crosshair } from 'react-feather'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface CalendarButtonOwnProps {
  value: Dayjs
  onChange: (updated: Dayjs) => void
  onPanelChange?: (updated: Dayjs) => void
  className?: string
}

export type CalendarButtonProps = CalendarButtonOwnProps

const CalendarButton = (props: CalendarButtonProps) => {
  const [isPromptOpen, openPrompt, closePrompt] = useToggle(false)
  const classes = useStyles()

  return (
    <>
      <IconButton size="small" onClick={openPrompt} className={props.className}>
        <Crosshair />
      </IconButton>
      <Prompt isOpen={isPromptOpen} onDismiss={closePrompt} title="Select Date">
        <CalendarPicker
          value={props.value}
          onChange={props.onChange}
          onPanelChange={props.onPanelChange}
          suppressHeaderMessage
        />
      </Prompt>
    </>
  )
}

export default React.memo(CalendarButton)
