import { RequestTriggers } from './types'

export async function requestWithTriggers<TResult = void>(
  triggers: RequestTriggers,
  request: () => Promise<TResult>
): Promise<TResult | null> {
  try {
    const res = await request()
    triggers?.onSuccess && triggers.onSuccess()
    return res
  } catch (e) {
    console.error(e)
    triggers?.onFailure && triggers.onFailure()
    return null
  }
}
