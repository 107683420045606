import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  useIsLoadingSubscriptionStatus,
  useIsInTrialPeriod,
  useTrialPeriodTimeRemaining,
} from '../Hooks/useSubscriptionStatus'
import { Box, Typography, IconButton } from '@material-ui/core'
import { AlertCircle, X, CreditCard } from 'react-feather'
import useAddSubscription from '../Pages/Account/useAddSubscription'
import Paper from './Paper'
import { createGlobalState } from 'react-use'
import AntIconButton from './AntIconButton'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2),
    },
    alertIcon: {
      marginRight: theme.spacing(1),
    },
  })
)

export interface FreeTrialWarningOwnProps {
  className?: string
}

export type FreeTrialWarningProps = FreeTrialWarningOwnProps

const useGlobalAlertDismissed = createGlobalState<boolean>(false)

const FreeTrialWarning = (props: FreeTrialWarningProps) => {
  const [
    globalAlertDismissed,
    setGlobalAlertDismissed,
  ] = useGlobalAlertDismissed()
  const classes = useStyles()
  const isLoadingSubscriptionStatus = useIsLoadingSubscriptionStatus()
  const isInTrialPeriod = useIsInTrialPeriod()
  const trialPeriodTimeRemaining = useTrialPeriodTimeRemaining()
  const { isLoading, onAddSubscription } = useAddSubscription()

  if (isLoadingSubscriptionStatus || !isInTrialPeriod || globalAlertDismissed)
    return null

  const message = `You only have ${trialPeriodTimeRemaining} day${
    trialPeriodTimeRemaining === 1 ? '' : 's'
  } remaining in your free trial.  Subscribe for only $5 a month to continue using Swell Stats.`

  return (
    <Paper className={clsx(classes.root, props.className)}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <AlertCircle className={classes.alertIcon} />
          <Typography variant="h6">{'Subscribe To Swell Stats'}</Typography>
        </Box>
        <IconButton onClick={() => setGlobalAlertDismissed(true)}>
          <X />
        </IconButton>
      </Box>
      <Box marginTop={2}>
        <Typography variant="body1">{message}</Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop={2}
      >
        <AntIconButton
          Icon={CreditCard}
          onClick={onAddSubscription}
          type="primary"
          size="large"
          loading={isLoading}
        >
          {'Subscribe'}
        </AntIconButton>
      </Box>
    </Paper>
  )
}

export default React.memo(FreeTrialWarning)
