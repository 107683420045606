import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { boxShadows } from '../theme'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      boxShadow: boxShadows[1],
      marginBottom: theme.spacing(0.5),
      padding: theme.spacing(2),
    },
  })
)

export interface MobileHeaderOwnProps {
  className?: string
  children: any
}

export type MobileHeaderProps = MobileHeaderOwnProps

const MobileHeader = (props: MobileHeaderProps) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, props.className)}>{props.children}</div>
  )
}

export default React.memo(MobileHeader)
