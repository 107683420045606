import React, { useState, useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Typography, Box } from '@material-ui/core'
import { ViewSelectValue } from '../AccountInitialization/UserAccounts'
import { UserAccountsContext } from '../AccountInitialization/Contexts'
import { Steps, Radio } from 'antd'
import Prompt from '../Prompt'
import { ChevronLeft, ChevronRight } from 'react-feather'
import useLogger from '../../Hooks/useLogger'
import NoAccountGaurd from './NoAccountGaurd'

const defaultViewSelectValue: ViewSelectValue = {
  account: null,
  property: null,
  view: null,
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '80%',
    },
    formControl: {
      marginBottom: theme.spacing(2.5),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    promptContent: {
      padding: theme.spacing(1),
    },
    stepContent: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(3),
      maxHeight: 500,
      minHeight: 160,
      overflow: 'auto',
      alignItems: 'center',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      marginTop: theme.spacing(2),
      justifyContent: 'space-between',
    },
    descriptiveLabel: {
      fontWeight: 'lighter',
    },
    labelText: {
      fontWeight: 'bold',
    },
  })
)

export interface ViewSelectorOwnProps {
  className?: string
  value: ViewSelectValue | null
  onChange: (newValue: ViewSelectValue) => void
  buttonText?: string
  isOpen: boolean
  onClose?: () => void
  dismissDisabled?: boolean
}

export type ViewSelectorProps = ViewSelectorOwnProps

type ComputedOptions = null | { value: string; display: string }[]

const ViewSelector = (props: ViewSelectorProps) => {
  const logger = useLogger()
  const classes = useStyles()
  const [workingValue, setWorkingValue] = useState(
    props.value || defaultViewSelectValue
  )
  const userAccounts = useContext(UserAccountsContext)
  console.log({ userAccounts })
  const [activeStep, setActiveStep] = useState(() => 0)

  const accounts = userAccounts.allAccounts.map((_) => ({
    value: _.id,
    display: _.name,
  }))

  const selectedAccount = !workingValue?.account
    ? null
    : userAccounts?.allAccounts.find((_) => _.id === workingValue.account)
  const properties = !selectedAccount
    ? null
    : selectedAccount.webProperties.map((_) => ({
        value: _.id,
        display: _.name,
      }))

  const selectedProperty = !workingValue?.property
    ? null
    : selectedAccount?.webProperties.find((_) => _.id === workingValue.property)
  const views = !selectedProperty
    ? null
    : selectedProperty.profiles.map((_) => ({ value: _.id, display: _.name }))

  const getOptions = (computedOptions: ComputedOptions) =>
    !computedOptions
      ? []
      : computedOptions.map((_) => (
          <Radio.Button
            key={_.value}
            value={_.value}
            disabled={
              userAccounts.isInvalidAccount(_.value) ||
              userAccounts.isInvalidPropertyId(_.value)
            }
          >
            {_.display}
          </Radio.Button>
        ))

  const renderAccountSelection = () => (
    <Radio.Group
      key="Account-select"
      value={workingValue.account || undefined}
      size="large"
      buttonStyle="solid"
      onChange={(newValue) => {
        setWorkingValue({
          account: newValue.target.value || null,
          property: null,
          view: null,
        })
        setActiveStep(1)
      }}
    >
      {getOptions(accounts)}
    </Radio.Group>
  )

  const renderProjectSelection = () => (
    <Radio.Group
      key="Project-select"
      value={workingValue.property || undefined}
      size="large"
      buttonStyle="solid"
      onChange={(newValue) => {
        setWorkingValue({
          ...workingValue,
          property: newValue.target.value || null,
          view: null,
        })
        setActiveStep(2)
      }}
    >
      {getOptions(properties)}
    </Radio.Group>
  )

  const renderViewSelection = () => (
    <Radio.Group
      key="View-select"
      value={workingValue.view || undefined}
      size="large"
      buttonStyle="solid"
      onChange={(newValue) => {
        setWorkingValue({
          ...workingValue,
          view: newValue.target.value || null,
        })
      }}
    >
      {getOptions(views)}
    </Radio.Group>
  )

  const renderStepButtons = () => {
    let prevStep: React.ReactElement = <div />

    if (activeStep > 0) {
      const label = activeStep === 2 ? 'Property' : 'Account'
      prevStep = (
        <Button
          size="small"
          onClick={() => setActiveStep(activeStep - 1)}
          startIcon={<ChevronLeft />}
        >
          {label}
        </Button>
      )
    }

    const nextStepLabel =
      activeStep === 2 ? 'Submit' : activeStep === 1 ? 'View' : 'Property'
    const onClick =
      activeStep === 2
        ? () => {
            logger.info(
              `Set view values to account: "${workingValue.account}", property: "${workingValue.property}", view: "${workingValue.view}",`
            )
            props.onChange(workingValue)
            props?.onClose?.()
          }
        : () => {
            setActiveStep(activeStep + 1)
          }

    const nextStepEnabled =
      activeStep === 2
        ? !!workingValue.view
        : activeStep === 1
        ? !!workingValue.property
        : !!workingValue.account

    const nextStep = (
      <Button
        disabled={!nextStepEnabled}
        size="small"
        onClick={onClick}
        endIcon={<ChevronRight />}
      >
        {nextStepLabel}
      </Button>
    )

    return (
      <div className={classes.buttonContainer}>
        {prevStep}
        {nextStep}
      </div>
    )
  }

  return (
    <Prompt
      title="Update Data Source"
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      dismissDisabled={props.dismissDisabled}
    >
      <div className={classes.promptContent}>
        <NoAccountGaurd>
          <Box width="100%" marginBottom={3} maxWidth={436} textAlign="center">
            <Typography variant="h6" className={classes.labelText}>
              {'The data source for SwellStats'}
            </Typography>
          </Box>
          <Steps progressDot current={activeStep} onChange={setActiveStep}>
            <Steps.Step title="Account" />
            <Steps.Step title="Property" disabled={activeStep < 1} />
            <Steps.Step title="View" disabled={activeStep < 2} />
          </Steps>
          <div className={classes.stepContent}>
            {activeStep === 0 && renderAccountSelection()}
            {activeStep === 1 && renderProjectSelection()}
            {activeStep === 2 && renderViewSelection()}
          </div>
          <Box width="100%" marginTop={2} textAlign="center" maxWidth={436}>
            <Typography variant="body1" className={classes.descriptiveLabel}>
              {
                [
                  'An account is your access point for Analytics',
                  'A property is a website, mobile application, or device (e.g., a kiosk or point-of-sale device.) An account can contain one or more properties.',
                  "A view is your access point for reports; a defined view of data from a property. You give users access to a view so they can see the reports based on that view's data. A property can contain one or more views.",
                ][activeStep] as string
              }
            </Typography>
          </Box>
          {renderStepButtons()}
        </NoAccountGaurd>
      </div>
    </Prompt>
  )
}

export default React.memo(ViewSelector)
