import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { AuthContext } from '../../Contexts/AuthContext'
import GlobalFilterButton from '../../SharedComponents/GlobalFilterButton'
import { Variant } from '@material-ui/core/styles/createTypography'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    labelRoot: {
      display: 'flex',
    },
    prefix: {
      fontWeight: 'bold',
    },
  })
)

export interface HeaderMessageOwnProps {
  variant: Variant
}

export type HeaderMessageProps = HeaderMessageOwnProps

const HeaderMessage = (props: HeaderMessageProps) => {
  const classes = useStyles({})
  const user = useContext(AuthContext)?.user?.displayName
  const prefix = user ? 'Welcome back, ' : 'Welcome back'

  return (
    <div className={classes.root}>
      <div className={classes.labelRoot}>
        <Typography variant={props.variant} className={classes.prefix}>
          {prefix}
        </Typography>
        {user && (
          <Typography variant={props.variant}>&nbsp;&nbsp;{user}</Typography>
        )}
      </div>
      <GlobalFilterButton />
    </div>
  )
}

export default React.memo(HeaderMessage)
