import React from 'react'
import useIsMobile from '../Hooks/useIsMobile'

export interface LayoutSwitchOwnProps {
  mobile: (children?: any) => React.ReactElement
  desktop: (children?: any) => React.ReactElement
  children?: any
}

export type LayoutSwitchProps = LayoutSwitchOwnProps

const LayoutSwitch = (props: LayoutSwitchProps) => {
  const isMobile = useIsMobile()

  return isMobile ? props.mobile(props.children) : props.desktop(props.children)
}

export default React.memo(LayoutSwitch)
