import { useContext } from 'react'
import firebase from 'firebase/app'
import { useCustomCompareEffect, createGlobalState } from 'react-use'
import dayjs from 'dayjs'
import useUserId from './useUserId'
import { useAlert } from '../Notifications'
import { AuthContext } from '../Contexts/AuthContext'

const TRIAL_PERIOD_LENGTH = 7

export type StripeSubscriptionStatus =
  | 'active'
  | 'past_due'
  | 'unpaid'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'custom:loading-status'
  | 'custom:no-record-exists'

export type StripeSubscription = {
  status: StripeSubscriptionStatus
}

const useGlobalSubscriptionStatus = createGlobalState<StripeSubscriptionStatus>(
  'custom:loading-status'
)

export default function useStripeSubscriptionStatus() {
  const [
    globalSubscriptionStatus,
    setGlobalSubscriptionStatus,
  ] = useGlobalSubscriptionStatus()
  const alert = useAlert()
  const userId = useUserId()
  useCustomCompareEffect(
    () => {
      if (globalSubscriptionStatus !== 'custom:loading-status') return

      firebase
        .firestore()
        .collection('customers')
        .doc(userId)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get()
        .then((snapshot) => {
          if (!snapshot?.docs?.[0]) {
            setGlobalSubscriptionStatus('custom:no-record-exists')
          } else {
            const doc = snapshot.docs[0]
            const status =
              (doc.data() as StripeSubscription)?.status ||
              'custom:no-record-exists'
            setGlobalSubscriptionStatus(status)
          }
        })
    },
    [userId, alert, setGlobalSubscriptionStatus],
    ([prevUserId], [nextUserId]) => prevUserId === nextUserId
  )

  return globalSubscriptionStatus
}

export function useIsLoadingSubscriptionStatus() {
  return useStripeSubscriptionStatus() === 'custom:loading-status'
}

export function useDoesUserHaveActiveSubscription() {
  return useStripeSubscriptionStatus() === 'active'
}

export function useUserDoesNotHaveActiveSubscription() {
  return useStripeSubscriptionStatus() !== 'active'
}

export function useTrialPeriodTimeRemaining() {
  const user = useContext(AuthContext)
  if (!user) return TRIAL_PERIOD_LENGTH

  const creationTimestamp = Number(user?.user.createdAt)
  const creationDate = new Date(creationTimestamp)
  const daysSinceCreation = dayjs(new Date()).diff(creationDate, 'd')
  const trialPeriodTimeRemaining =
    TRIAL_PERIOD_LENGTH - Math.floor(daysSinceCreation)
  return trialPeriodTimeRemaining
}

export function useIsInTrialPeriod() {
  const isLoadingSubscriptionStatus = useIsLoadingSubscriptionStatus()
  const doesUserHaveActiveSubscription = useDoesUserHaveActiveSubscription()
  const trialPeriodTimeRemaining = useTrialPeriodTimeRemaining()

  if (isLoadingSubscriptionStatus) return true

  if (doesUserHaveActiveSubscription) return false

  return trialPeriodTimeRemaining >= 0
}

export function useIsTrialExpired() {
  const isLoadingSubscriptionStatus = useIsLoadingSubscriptionStatus()
  const doesUserHaveActiveSubscription = useDoesUserHaveActiveSubscription()
  const trialPeriodTimeRemaining = useTrialPeriodTimeRemaining()

  return (
    !isLoadingSubscriptionStatus &&
    !doesUserHaveActiveSubscription &&
    trialPeriodTimeRemaining < 0
  )
}
