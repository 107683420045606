import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Machine } from 'xstate'
import { useMachine } from '@xstate/react'
import { Fade, CircularProgress } from '@material-ui/core'

// This machine is completely decoupled from React
export const chainedTransitionMachine = Machine({
  id: 'chained-transition',
  initial: 'loader',
  states: {
    loader: {
      on: {
        START_LOADING: 'loader',
        END_LOADING: 'neither',
        LOADER_OUT: 'graph',
        GRAPH_OUT: 'loader',
      },
    },
    neither: {
      on: {
        START_LOADING: 'loader',
        END_LOADING: 'graph',
        LOADER_OUT: 'graph',
        GRAPH_OUT: 'loader',
      },
    },
    graph: {
      on: {
        START_LOADING: 'neither',
        END_LOADING: 'neither',
        LOADER_OUT: 'graph',
        GRAPH_OUT: 'loader',
      },
    },
  },
})

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loader: {
      color: theme.palette.primary.main,
      width: 100,
      height: 100,
      display: 'block',
    },
  })
)

export interface GraphLoaderOwnProps {
  isLoading: boolean
  children: any
}

export type GraphLoaderProps = GraphLoaderOwnProps

const GraphLoader = ({ isLoading, children }: GraphLoaderProps) => {
  const [current, send] = useMachine(chainedTransitionMachine)
  useEffect(() => {
    send(isLoading ? 'START_LOADING' : 'END_LOADING')
  }, [isLoading, send])

  const classes = useStyles()

  return (
    <>
      <Fade
        in={current.matches('loader')}
        onExited={() => send('LOADER_OUT')}
        timeout={500}
        unmountOnExit
      >
        <div className={classes.root}>
          <CircularProgress />
        </div>
      </Fade>
      <Fade
        in={current.matches('graph')}
        onExited={() => send('GRAPH_OUT')}
        timeout={500}
      >
        {children}
      </Fade>
    </>
  )
}

export default React.memo(GraphLoader)
