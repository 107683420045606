import AnalyticsDataPoint from './AnaltyicsDataPoint'
import _sum from 'lodash/sum'
import _mean from 'lodash/mean'
import _minBy from 'lodash/minBy'
import _maxBy from 'lodash/maxBy'
import { Range } from '../useAPIQuery'
import AnalyticsDataCollection from './AnalyticsDataCollection'
import { Span } from './Selections'
import dayjs, { Dayjs } from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import { getSpanHourValue } from '../../../util/extraDayJsUtils'

dayjs.extend(weekday)
dayjs.extend(dayOfYear)

export type AggregationProjection = {
  range: Range
  value: number
}

export type Projection<TResult> = (dataPoints: AnalyticsDataPoint[]) => TResult

type NumericAggregaiton = (values: number[]) => number
const getNumericProjection = (
  aggregation: NumericAggregaiton
): Projection<AggregationProjection> => (
  dataPoints: AnalyticsDataPoint[]
): AggregationProjection => {
  const values = dataPoints.map((_) => _.value)
  const dates = dataPoints.map((_) => _.date)
  const value = aggregation(values)
  const minDate = _minBy(dates, (_) => _.unix())
  const maxDate = _maxBy(dates, (_) => _.unix())
  return {
    range: {
      start: minDate,
      end: maxDate,
    },
    value,
  } as AggregationProjection
}

export const sumProjection: Projection<AggregationProjection> = getNumericProjection(
  _sum
)

export const meanProjection: Projection<AggregationProjection> = getNumericProjection(
  _mean
)

export type WindowProjectionAggregation = 'avg'
/**
 * Frames the data into the desired "window out of frame" (day of week for example) gbut allow earoup.  It will then apply this window to some start date
 * Note that these are not "real" results at some start date, sily comparing to other spans
 * @param frame - the span "out of" which the windowSpan is evaluated.
 * @param agg - how the different spans are merged by hour
 * @param referenceDate - the date which the results are transposed on
 */
export const frameProjection = (
  frame: Span,
  agg: WindowProjectionAggregation,
  referenceDate: Dayjs
): Projection<AnalyticsDataCollection> => (
  dataPoints: AnalyticsDataPoint[]
) => {
  type Record = { hour: number; datapoints: AnalyticsDataPoint[] }
  const recordHash: { [hour: number]: Record } = {}
  dataPoints.forEach((dataPoint) => {
    const hourValue = getSpanHourValue(dataPoint.date, frame)
    const existingRecord = recordHash[hourValue]
    if (existingRecord) {
      existingRecord.datapoints.push(dataPoint)
    } else {
      recordHash[hourValue] = { hour: hourValue, datapoints: [dataPoint] }
    }
  })

  const newDataPoints = Object.values(recordHash).map((rec) => {
    const date = referenceDate.add(rec.hour, 'hours')
    const value = _mean(rec.datapoints.map((_) => _.value))
    return new AnalyticsDataPoint(date, value)
  })

  return new AnalyticsDataCollection(newDataPoints, referenceDate)
}

/**
 * Buckets the input (probably hourly buckets) into the specified span buckets.
 *
 * This is essentially "lowering" the time resolution
 */
export const spanBucketProjection = (
  span: Span
): Projection<AnalyticsDataCollection> => (
  dataPoints: AnalyticsDataPoint[]
) => {
  const getCompValue = (date: Dayjs) => date.startOf(span).unix()

  type Record = { compValue: number; datapoints: AnalyticsDataPoint[] }
  const records: Record[] = []
  dataPoints.forEach((dataPoint) => {
    const compValue = getCompValue(dataPoint.date)
    const existingRecord = records.find((_) => _.compValue === compValue)
    if (existingRecord) {
      existingRecord.datapoints.push(dataPoint)
    } else {
      records.push({ compValue, datapoints: [dataPoint] })
    }
  })

  const newDataPoints = records.map((rec) => {
    const date = dayjs.unix(rec.compValue).startOf(span)
    const value = _sum(rec.datapoints.map((_) => _.value))
    return new AnalyticsDataPoint(date, value)
  })

  return new AnalyticsDataCollection(newDataPoints, dayjs())
}

export type XYDatapoint = {
  x: number
  y: number
}

export type Resolution = 'hour' | 'day' | 'week' | 'month'

/**
 * projects data into a time series of x-y coordinates.  x index
 */
export const xyProjections = (
  refDate: Dayjs,
  resolution: Resolution = 'hour'
): Projection<XYDatapoint[]> => (dataPoints: AnalyticsDataPoint[]) => {
  const getXValue = (date: Dayjs) => {
    const diff = date.diff(refDate, resolution)
    return diff
  }
  return dataPoints.map((_) => ({
    x: getXValue(_.date),
    y: _.value,
  }))
}

/**
 * accumulates values over time series
 */
export const accumulate: Projection<AnalyticsDataCollection> = (
  dataPoints: AnalyticsDataPoint[]
) => {
  let runningTotal = 0
  const newDatapoints = dataPoints.map((_) => {
    runningTotal += _.value
    return new AnalyticsDataPoint(_.date, runningTotal)
  })

  return new AnalyticsDataCollection(newDatapoints)
}
