import { WindowConfig, getReferenceDate } from '../../util/windowingUtils'
import _isFunction from 'lodash/isFunction'
import { getWeekOfMonth, getWeekOfYear } from '../../util/extraDayJsUtils'
import { Resolution } from '../../Contexts/DataProvider/AnalyticsData/Projections'

export type TickFormatter = (xValue: number) => string

const formats = {
  hour: {
    day: 'h a',
    week: 'dddd h a',
    month: 'Do h a',
    year: 'MMM Do h a',
  },
  day: {
    day: '',
    week: 'dddd',
    month: 'Do',
    year: 'MMM Do',
  },
  week: {
    day: '',
    week: '',
    month: getWeekOfMonth,
    year: getWeekOfYear,
  },
  month: {
    day: '',
    week: '',
    month: '',
    year: '',
  },
}

const useXFormatter = (
  resolution: Resolution,
  config: WindowConfig[]
): TickFormatter => (xValue: number) => {
  const refWidnowConfig = config[0]
  const referenceDate = getReferenceDate(refWidnowConfig)
  const asDayjsValue = referenceDate.add(xValue, 'hours')
  const formatter = formats[resolution][refWidnowConfig.frame]
  return _isFunction(formatter)
    ? String(formatter(asDayjsValue))
    : asDayjsValue.format(formatter)
}

export default useXFormatter
