import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface LabeledContentOwnProps {
  content: string
}

export type LabeledContentProps = LabeledContentOwnProps

const LabeledContent = (props: LabeledContentProps) => {
  const classes = useStyles()

  return <Typography variant="h6">{props.content}</Typography>
}

export default React.memo(LabeledContent)
