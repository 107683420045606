import { isProdEnv } from './envUtil'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { useEffect } from 'react'

/**
 * Conditionally start bugsnag client if in prod env
 */
export const startBugsnagClient = () => {
  if (isProdEnv) {
    console.log('Starting bugsnag client')
    Bugsnag.start({
      apiKey: '6a356440e88a0d224a51ffe9fc40fa4e',
      plugins: [new BugsnagPluginReact()],
      maxBreadcrumbs: 100,
    })
  }
}

export function useSetBugsnagUser(userEmail?: string, userId?: string) {
  useEffect(() => {
    if (userEmail && userId && isProdEnv) Bugsnag.setUser(userId, userEmail)
  }, [userEmail, userId])
}
