import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Paper from '../Paper'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 100,
      justifyContent: 'space-around',
    },
    header: {
      height: 20,
      width: '100%',
    },
    content: {
      minHeight: 0,
      flex: 1,
      width: '100%',
    },
  })
)

export interface CardOwnProps {
  header: any
  content: any
  className?: string
}

export type CardProps = CardOwnProps

const Card = (props: CardProps) => {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(classes.root, props.className)}
      elevation={1}
      variant="elevation"
    >
      <div className={classes.header}>{props.header}</div>
      <div className={classes.content}>{props.content}</div>
    </Paper>
  )
}

export default React.memo(Card)
