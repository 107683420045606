import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Logo from './Logos/Logo'
import HazeBackground from './HazeBackground'
import Paper from './Paper'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    paper: {
      width: 300,
      height: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    brandedText: {
      marginBottom: theme.spacing(2),
    },
  })
)

export interface LoadingViewOwnProps {}

export type LoadingViewProps = LoadingViewOwnProps

const LoadingView = (props: LoadingViewProps) => {
  const classes = useStyles()

  return (
    <HazeBackground className={classes.root}>
      <Paper className={classes.paper}>
        <Logo width={240} height={100}/>
      </Paper>
    </HazeBackground>
  )
}

export default React.memo(LoadingView)
