import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import HeaderMessage from '../HeaderMessage'
import { motion, Variants } from 'framer-motion'
import MobileHeader from '../../../SharedComponents/MobileHeader'
import Progress from '../../../SharedComponents/Widgets/Progress'
import CalloutCard from '../../../SharedComponents/Widgets/CalloutCard'
import Graph from '../Graph'
import clsx from 'clsx'
import FreeTrialWarning from '../../../SharedComponents/FreeTrialWarning'
import SubscriptionGaurd from '../../../SharedComponents/SubscriptionGaurd'
import SwitchMetricPrompt from '../../../SharedComponents/SwitchMetricPrompt'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    mainContent: {
      minHeight: 0,
      flex: 1,
      width: '100%',
      overflowY: 'auto',
    },
    cardContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    homeItem: {
      marginBottom: theme.spacing(2),
    },
    graph: {
      height: '70vh',
    },
    switchMetricPrompt: {
      marginBottom: theme.spacing(4),
    },
  })
)

export interface MobileHomeOwnProps {}

export type MobileHomeProps = MobileHomeOwnProps

const item = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
    },
  },
} as Variants

const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const fadeIn = (element: any) => (
  <motion.div variants={item}>{element}</motion.div>
)

const MobileHome = (props: MobileHomeProps) => {
  const classes = useStyles()

  return (
    <SubscriptionGaurd>
      <div className={classes.root}>
        <MobileHeader>
          <HeaderMessage variant="h6" />
        </MobileHeader>
        <div className={classes.mainContent}>
          <motion.div
            className={classes.cardContainer}
            initial="hidden"
            animate="show"
            variants={container}
          >
            <FreeTrialWarning className={classes.homeItem} />
            <SwitchMetricPrompt className={classes.homeItem} />
            {fadeIn(
              <Graph className={clsx(classes.homeItem, classes.graph)} />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.homeItem}
                config={{
                  title: 'Today',
                  windowConfig: {
                    span: 'day',
                    aggregation: 'last',
                    frame: 'day',
                    rangeSpan: 'day',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.homeItem}
                config={{
                  title: "Today's Goal",
                  windowConfig: {
                    span: 'day',
                    aggregation: 'last',
                    frame: 'day',
                    rangeSpan: 'day',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.homeItem}
                config={{
                  title: 'This Week',
                  windowConfig: {
                    span: 'week',
                    aggregation: 'last',
                    frame: 'week',
                    rangeSpan: 'week',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.homeItem}
                config={{
                  title: "Week's Goal",
                  windowConfig: {
                    span: 'week',
                    aggregation: 'last',
                    frame: 'week',
                    rangeSpan: 'week',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.homeItem}
                config={{
                  title: 'This Month',
                  windowConfig: {
                    span: 'month',
                    aggregation: 'last',
                    frame: 'month',
                    rangeSpan: 'month',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.homeItem}
                config={{
                  title: "Months's Goal",
                  windowConfig: {
                    span: 'month',
                    aggregation: 'last',
                    frame: 'month',
                    rangeSpan: 'month',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <CalloutCard
                className={classes.homeItem}
                config={{
                  title: 'This Year',
                  windowConfig: {
                    span: 'year',
                    aggregation: 'last',
                    frame: 'year',
                    rangeSpan: 'year',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
            {fadeIn(
              <Progress
                className={classes.homeItem}
                config={{
                  title: "Year's Goal",
                  windowConfig: {
                    span: 'year',
                    aggregation: 'last',
                    frame: 'year',
                    rangeSpan: 'year',
                    rangeIterations: 1,
                  },
                }}
              />
            )}
          </motion.div>
        </div>
      </div>
    </SubscriptionGaurd>
  )
}

export default React.memo(MobileHome)
