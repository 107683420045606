import { useMemo, useState, useCallback, useEffect } from 'react'
import { Auth } from '../Contexts/AuthContext'
import Bugsnag from '@bugsnag/js'
import { useSetBugsnagUser } from '../util/bugsnagUtil'

type PersistedAuthState = {
  auth: Auth
  storageDate: string
}

const ONE_HOUR = 60 * 60 * 1000
const storageKey = `@@swellstats/localStorageState/auth`

const loadAuth = () => {
  const rawData = window.localStorage.getItem(storageKey)
  if (!rawData) return null

  const authStorage = JSON.parse(rawData) as PersistedAuthState
  if (!authStorage?.storageDate) return null

  const isLessThan1HourOld =
    new Date().getTime() - new Date(authStorage.storageDate).getTime() <
    ONE_HOUR

  return isLessThan1HourOld ? authStorage.auth : null
}

export default function useAuthState() {
  const initialAuth = useMemo(() => loadAuth(), [])
  const [authState, setAuthState] = useState(initialAuth)

  const userEmail = authState?.user.email
  const userId = authState?.user?.uid

  useSetBugsnagUser(userEmail, userId)

  useEffect(() => {
    if (userEmail && userId) Bugsnag.setUser(userId, userEmail)
  }, [userEmail, userId])

  const interceptSetAuthState = useCallback(
    (newAuth: Auth) => {
      // idfk... this adds oauth tokens
      const destringified = JSON.parse(JSON.stringify(newAuth))
      setAuthState(destringified)
      window.localStorage.setItem(
        storageKey,
        JSON.stringify({
          auth: destringified,
          storageDate: new Date().toISOString(),
        } as PersistedAuthState)
      )
    },
    [setAuthState]
  )

  return {
    authState,
    setAuthState: interceptSetAuthState,
  }
}
