import firebase from 'firebase/app'
import 'firebase/analytics'
import { useContext, useMemo } from 'react'
import { AuthContext } from '../Contexts/AuthContext'

export type Logger = {
  info: (message: string) => void
  warn: (message: string) => void
  error: (message: string) => void
}

type LogLevel = 'INFO' | 'WARN' | 'ERROR'

function useLogger(): Logger {
  const analytics = useMemo(() => {
    const app = firebase.app()
    return firebase.analytics(app)
  }, [])
  const auth = useContext(AuthContext)
  const email = auth?.user?.email || 'NO_EMAIL_REGISTERED'
  const userId = auth?.user?.uid || 'NO_UID_REGISTERED'

  return useMemo<Logger>(() => {
    const log = (level: LogLevel, message: string) => {
      analytics.logEvent('log_event', {
        level,
        message,
        email,
        userId,
      })
    }

    const getLogger = (level: LogLevel) => (message: string) => {
      log(level, message)
    }

    return {
      info: getLogger('INFO'),
      warn: getLogger('WARN'),
      error: getLogger('ERROR'),
    }
  }, [analytics, email, userId])
}

export default useLogger
