import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import AccountSection from './AccountSection'
import { AlertCircle, CreditCard } from 'react-feather'
import {
  useIsLoadingSubscriptionStatus,
  useDoesUserHaveActiveSubscription,
  useIsInTrialPeriod,
  useTrialPeriodTimeRemaining,
} from '../../Hooks/useSubscriptionStatus'
import { Typography, Box } from '@material-ui/core'
import useAddSubscription from './useAddSubscription'
import AntIconButton from '../../SharedComponents/AntIconButton'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
    contentContainer: {
      width: '100%',
    },
  })
)

export interface CreateSubscriptionSectionOwnProps {
  className?: string
}

export type CreateSubscriptionSectionProps = CreateSubscriptionSectionOwnProps

const CreateSubscriptionSection = (props: CreateSubscriptionSectionProps) => {
  const classes = useStyles()
  const isLoadingSubscriptionStatus = useIsLoadingSubscriptionStatus()
  const userHasActiveSubscription = useDoesUserHaveActiveSubscription()
  const isInTrialPeriod = useIsInTrialPeriod()
  const trialPeriodTimeRemaining = useTrialPeriodTimeRemaining()
  const { isLoading, onAddSubscription } = useAddSubscription()

  if (isLoadingSubscriptionStatus || userHasActiveSubscription) return null

  const trialMessage = isInTrialPeriod
    ? `Your free trial only has ${trialPeriodTimeRemaining} day${
        trialPeriodTimeRemaining === 1 ? '' : 's'
      } remaining.`
    : `Your free trial has expired.`

  return (
    <AccountSection
      label="Subscribe To Swell Stats!"
      headerContent={<AlertCircle />}
      contentClassName={classes.contentContainer}
      className={props.className}
    >
      <Box width="100%" marginBottom={2}>
        <Typography variant="body1">
          {`${trialMessage}  To continue using Swell Stats, subscribe for only $5.00 a month!`}
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <AntIconButton
          Icon={CreditCard}
          onClick={onAddSubscription}
          type="primary"
          size="large"
          loading={isLoading}
        >
          {`Subscribe to Swell Stats!`}
        </AntIconButton>
      </Box>
    </AccountSection>
  )
}

export default React.memo(CreateSubscriptionSection)
