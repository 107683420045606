import axios, { AxiosInstance } from 'axios'
import { RequestTriggers } from './types'
import { requestWithTriggers } from './util'
import routes from '../util/routes'
import { TimeRange, TimeWindow, Channels } from '../util/queryUtil'
import { DimensionId, MetricId } from '../util/GAPIRequestTypes'

export type AccountId = string

export type View = {
  kind: string
  id: string
  name: string
  type: 'WEB'
}

export type Property = {
  kind: string
  id: string
  name: string
  internalWebPropertyId: string
  level: 'STANDARD'
  websiteUrl: string
  profiles: View[]
}

export type AccountSummary = {
  id: AccountId
  kind: string
  name: string
  webProperties: Property[]
}

export type AccountSummaryResponse = {
  kind: string
  username: string
  totalResults: number
  startIndex: number
  itemsPerPage: number
  items: Array<AccountSummary>
}

export type NumericString = string

export type Row = {
  dimensions: [string]
  metrics: [{ values: [string] }]
}

export type Report = {
  viewId: string
  columnHeader: {
    dimensions: DimensionId[]
  }
  dateRanges: [
    {
      startDate: string
      endDate: string
    }
  ]
  metrics: [
    {
      expression: MetricId
    }
  ]
  metricHeader: {
    metricHeaderEntries: {
      name: MetricId
      type: 'INTEGER'
    }[]
  }
  dimensions: any[]
  data: {
    rows: {
      dimensions: NumericString[]
      metrics: {
        values: [NumericString]
      }[]
    }[]
  }
  orderBys: [
    {
      fieldName: DimensionId
      orderType: 'HISTOGRAM_BUCKET'
      sortOrder: 'ASCENDING'
    }
  ]
  segments?: [
    {
      segmentId: string
    }
  ]
  pageToken?: string
}

export type ReportsResponse = {
  reports: [
    {
      columnHeader: {
        dimensions: DimensionId[]
      }
      metricHeader: {
        metricHeaderEntries: {
          name: MetricId
          type: 'INTEGER'
        }[]
      }
      data: {
        rows: Row[]
      }
      nextPageToken?: string
    }
  ]
}

export default class GoogleAPIService {
  private _axiosInstance: AxiosInstance
  constructor(authToken: string) {
    this._axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  }

  async getAccountSummary(
    requestTriggers: RequestTriggers
  ): Promise<AccountSummary[] | null> {
    return requestWithTriggers<AccountSummary[]>(requestTriggers, async () => {
      const res = await this._axiosInstance.get<AccountSummaryResponse>(
        routes.googleAPI.accountSummaries
      )
      return res?.data?.items
    })
  }

  async reportsV2(report: Report, requestTriggers: RequestTriggers) {
    return requestWithTriggers<Row[]>(requestTriggers, async () => {
      const rows: Row[] = []
      let nextToken: string | null = null
      do {
        const copied: Report = { ...report }

        if (nextToken) {
          copied.pageToken = nextToken
        }

        const apiRes = await this._axiosInstance.post<ReportsResponse>(
          routes.googleAPI.reports,
          { reportRequests: [copied] }
        )

        rows.push(...(apiRes?.data?.reports?.[0]?.data?.rows || []))
        nextToken = apiRes?.data?.reports?.[0]?.nextPageToken || null
      } while (nextToken)

      return rows
    })
  }
}
