import dayjs from 'dayjs'
import GoogleAPIService from '../../services/GoogleAPIService'
import { APIValuesAndView, Range } from './useAPIQuery'
import AnalyticsDataCollection from './AnalyticsData/AnalyticsDataCollection'
import { buildQueryDateParameters2, PersistedDataSet } from './PersistedData'
import { RequestTriggers } from '../../services/types'
import buildQuery from './buildQuery'

export type LoadCallbacks = {
  /**
   * The loader incrementally loads data.  The consumer should intelligently
   * update the displayed dataset
   */
  onProgressReported: (newDataPoints: AnalyticsDataCollection) => void
  /**
   * It's possible that data load could go stale with new values.
   * This checks with the consumer if it should still be doing api queries
   */
  shouldStillLoad: (apiValuesAndView: APIValuesAndView) => boolean
  /**
   * All data was successfully loaded.  The history can now be persisted
   */
  onCompletion: () => void
}

const chunkDaySpans = [
  1,
  4,
  4,
  7,
  7,
  7,
  60,
  60,
  365,
  365,
  365,
  365,
  365,
  365,
  365,
  365,
]

export default class DataLoader {
  static getDefaultRange() {
    const start = dayjs()
      .startOf('day')
      .subtract(366 * 2, 'days')
    const end = dayjs().add(1, 'day').startOf('day')
    return { start, end }
  }

  static reconcileLoadRangeFromPersistedDataset(
    persistedDataset?: PersistedDataSet
  ): Range {
    if (!persistedDataset) return DataLoader.getDefaultRange()

    return {
      start: dayjs(persistedDataset.endDate).startOf('day'),
      end: dayjs().add(1, 'day').startOf('day'),
    }
  }

  private _service: GoogleAPIService
  private _apiValuesAndViews: APIValuesAndView
  private _requestTriggers: RequestTriggers
  constructor(
    service: GoogleAPIService,
    apiValuesAndView: APIValuesAndView,
    requestTriggers: RequestTriggers
  ) {
    this._service = service
    this._apiValuesAndViews = apiValuesAndView
    this._requestTriggers = requestTriggers
  }

  /**
   * Chunk out input range into progressively larger spans starting from current to the past.
   * This allows optimized loading to progressively render data.
   *
   * The end result is that the user sees current data much quicker
   */
  private _chunkOutRange(range: Range): Range[] {
    const resultRanges: Range[] = []
    let endOfRangeReached = false
    let rangeEnd = range.end

    do {
      const currentChunkSpan = chunkDaySpans[resultRanges.length]
      let start = rangeEnd.add(-1 * currentChunkSpan, 'days')

      if (start.isBefore(range.start)) {
        start = range.start
        endOfRangeReached = true
      }

      resultRanges.push({ start, end: rangeEnd })
      rangeEnd = start
    } while (!endOfRangeReached)

    return resultRanges
  }

  private async _loadChunkFromApi(
    range: Range
  ): Promise<AnalyticsDataCollection> {
    const queryDateParameters = buildQueryDateParameters2(
      range,
      this._apiValuesAndViews
    )

    if (!queryDateParameters.queryDateDimension.histogramBuckets?.length) {
      /*
        this seems to happen if the ranges perfectly line up.  The api will throw
        an error if the request goes through and it would only return an empty dataset
      */
      console.warn(
        `Generated an empty histogram bucket query.  Start: ${range.start.toISOString()}, End: ${range.end.toISOString()}`
      )
      return AnalyticsDataCollection.initEmpty()
    }

    const query = buildQuery(
      this._apiValuesAndViews,
      queryDateParameters.queryDateRange,
      queryDateParameters.queryDateDimension
    )
    const rows = await this._service.reportsV2(query, this._requestTriggers)
    return AnalyticsDataCollection.initFromAPIData(rows, range)
  }

  public async loadRange(range: Range, callbacks: LoadCallbacks) {
    const rangeChunks = this._chunkOutRange(range)
    for (let chunkIndex = 0; chunkIndex < rangeChunks.length; chunkIndex++) {
      if (!callbacks.shouldStillLoad(this._apiValuesAndViews)) return

      const newCollection = await this._loadChunkFromApi(
        rangeChunks[chunkIndex]
      )

      callbacks.shouldStillLoad(this._apiValuesAndViews) &&
        callbacks.onProgressReported(newCollection)
    }

    callbacks.onCompletion()
  }
}
