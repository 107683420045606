import firebase from 'firebase/app'
import useUserId from '../../Hooks/useUserId'
import { useAlert } from '../../Notifications'
import { useStripe } from '@stripe/react-stripe-js'
import useToggle from '../../Hooks/useToggle'

export type ReturnValue = {
  isLoading: boolean
  onAddSubscription: () => void
}

function useAddSubscription(): ReturnValue {
  const userId = useUserId()
  const alert = useAlert()
  const stripe = useStripe()
  const [isLoading, setLoading] = useToggle(false)
  const onAddSubscription = async () => {
    setLoading()
    const docRef = await firebase
      .firestore()
      .collection('customers')
      .doc(userId)
      .collection('checkout_sessions')
      .add({
        price: 'price_1IM26HCihcRjp8IGDA3C4Jew',
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      })

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data() || {}
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert('error', `An error occured: ${error.message}`)
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        stripe?.redirectToCheckout({ sessionId })
      }
    })
  }

  return { onAddSubscription, isLoading }
}

export default useAddSubscription
