import _isEqual from 'lodash/isEqual'
import dayjs, { Dayjs } from 'dayjs'
import { DATA_HOUR_RESOLUTION } from '../../util/constants'
import AnalyticsDataCollection from './AnalyticsData/AnalyticsDataCollection'
import { APIValuesAndView } from './useAPIQuery'

export type PersistedDataPoint = {
  date: string
  value: number
}

export type PersistedDataSet = {
  apiValuesAndView: APIValuesAndView
  startDate: string
  endDate: string
  nextRequestDate: string
  datapoints: PersistedDataPoint[]
}

export type PersistedDatasets = PersistedDataSet[]

export type APIQueryDateRange = [
  {
    startDate: string
    endDate: string
  }
]

export type APIQueryDateDimension = {
  name: 'ga:nthHour'
  histogramBuckets: string[]
}

export type APIQueryMetadata = {
  range: Range
  nextRequestDate: Dayjs
  existingDataset: PersistedDataSet | null
  apiValuesAndView: APIValuesAndView
}

export type QueryDateParameters = {
  queryDateRange: APIQueryDateRange
  queryDateDimension: APIQueryDateDimension
  metadata: APIQueryMetadata
}

export const findTargetPersistedDataset = (
  allPersistedData: PersistedDatasets,
  valuesAndView: APIValuesAndView
) =>
  allPersistedData.filter((_) =>
    _isEqual(_.apiValuesAndView, valuesAndView)
  )[0] || null

type Range = {
  start: Dayjs
  end: Dayjs
}

const generateDateDimension = (range: Range): APIQueryDateDimension => {
  const numHours = range.end.diff(range.start, 'hours')
  const numBuckets = Math.ceil(numHours / DATA_HOUR_RESOLUTION)
  const buckets: number[] = []
  for (let i = 0; i < numBuckets; i++) buckets.push(i * DATA_HOUR_RESOLUTION)
  return {
    name: 'ga:nthHour',
    histogramBuckets: buckets.map(String),
  }
}

/**
 * gets the time of the last bucket and the time of the next bucket
 */
const getNextAndPreviousBuckets = () => {
  const current = dayjs().startOf('hour')
  const prevHourBucket =
    Math.floor(current.hour() / DATA_HOUR_RESOLUTION) * DATA_HOUR_RESOLUTION
  const nextHourBucket = prevHourBucket + DATA_HOUR_RESOLUTION
  return {
    prev: current.hour(prevHourBucket),
    next: current.hour(nextHourBucket),
  }
}

const buildRange = (persistedBucket: PersistedDataSet | null): Range => {
  let start = dayjs()
    .startOf('day')
    .subtract(366 * 2, 'days')

  if (persistedBucket?.endDate && dayjs(persistedBucket.endDate).isValid()) {
    start = dayjs(persistedBucket.endDate).startOf('day')
  }

  return {
    start,
    end: dayjs().endOf('day'),
  }
}

export const buildQueryDateParameters = (
  allPersistedData: PersistedDatasets,
  apiValuesAndView: APIValuesAndView
): QueryDateParameters => {
  const existingBucket = findTargetPersistedDataset(
    allPersistedData,
    apiValuesAndView
  )
  const range = buildRange(existingBucket)
  const queryDateRange: APIQueryDateRange = [
    {
      startDate: range.start.format('YYYY-MM-DD'),
      endDate: range.end.format('YYYY-MM-DD'),
    },
  ]
  const queryDateDimension = generateDateDimension(range)
  const metadata: APIQueryMetadata = {
    range,
    nextRequestDate: getNextAndPreviousBuckets().next,
    existingDataset: existingBucket,
    apiValuesAndView,
  }

  return {
    queryDateRange,
    queryDateDimension,
    metadata,
  }
}

export const buildQueryDateParameters2 = (
  range: Range,
  apiValuesAndView: APIValuesAndView
): QueryDateParameters => {
  const queryDateRange: APIQueryDateRange = [
    {
      startDate: range.start.format('YYYY-MM-DD'),
      endDate: range.end.format('YYYY-MM-DD'),
    },
  ]
  const queryDateDimension = generateDateDimension(range)
  const metadata: APIQueryMetadata = {
    range,
    nextRequestDate: getNextAndPreviousBuckets().next,
    existingDataset: null,
    apiValuesAndView,
  }

  return {
    queryDateRange,
    queryDateDimension,
    metadata,
  }
}

export const reconcilePersistedDataSetUpdates = (
  allPersistedData: PersistedDatasets,
  newDataset: AnalyticsDataCollection,
  apiValuesAndView: APIValuesAndView
): PersistedDatasets => {
  const dataCollectionRange = newDataset.range

  const newPersistedDataset: PersistedDataSet = {
    apiValuesAndView: apiValuesAndView,
    startDate: dataCollectionRange.start.toISOString(),
    endDate: dataCollectionRange.end.toISOString(),
    nextRequestDate: dataCollectionRange.end

      .add(DATA_HOUR_RESOLUTION, 'h')
      .toISOString(),
    datapoints: newDataset.asPersistedPoints,
  }

  const collectionWithoutTarget = (allPersistedData || []).filter(
    (_) => !_isEqual(_.apiValuesAndView, newPersistedDataset.apiValuesAndView)
  )
  return [...collectionWithoutTarget.slice(-2), newPersistedDataset]
}
