import _flatten from 'lodash/flatten'
import {
  AccountSummary,
  AccountId,
  View,
  Property,
} from '../../services/GoogleAPIService'

export type ViewSelectValue = {
  account: string | null
  property: string | null
  view: string | null
}

export const defaultViewSelectValue: ViewSelectValue = {
  account: null,
  property: null,
  view: null,
}

export type CoreUserAccountState = {
  allAccounts: AccountSummary[] | null
  selectedView: ViewSelectValue
  isInitializing: boolean
}

const initialState: CoreUserAccountState = {
  allAccounts: null,
  selectedView: defaultViewSelectValue,
  isInitializing: true,
}

export default class UserAccounts {
  static isValidView(
    userAccounts: UserAccounts,
    selectedView: ViewSelectValue
  ): boolean {
    const withValue = userAccounts.setSelectedView(selectedView)
    return (
      !!selectedView?.view && withValue.selectedView?.id === selectedView?.view
    )
  }

  static initNew(): UserAccounts {
    return new UserAccounts(initialState)
  }

  private _coreState: CoreUserAccountState

  constructor(coreState: CoreUserAccountState) {
    this._coreState = coreState
  }

  public get isInitialized(): boolean {
    return !this._coreState.isInitializing
  }

  /**
   * Certain accounts don't have properties and views.  These cannot be used in swellstats
   */
  public get invalidAccountIds(): string[] {
    return (this.allAccounts || [])
      .filter((account) => {
        const allSubViews = (account?.webProperties || []).map(
          (_) => _.profiles
        )
        const flattenedSubViews = _flatten(allSubViews)
        return !flattenedSubViews.length
      })
      .map((_) => _.id)
  }

  /**
   * Certain properties don't have views, these cannot be used in swellstats
   */
  public get invalidPropertyIds(): string[] {
    const allProperties = (this.allAccounts || [])
      .map((account) => account?.webProperties)
      .filter(Boolean)
    const propertiesFlattened = _flatten(allProperties)
    return propertiesFlattened
      .filter((_) => !_?.profiles?.length)
      .map((_) => _.id)
      .filter(Boolean)
  }

  /**
   * This account may not have a property and view and therefore cannot be used in swellstats
   */
  public isInvalidAccount(accountId: string) {
    return this.invalidAccountIds.includes(accountId)
  }

  /**
   * This property may not have a view and therefore cannot be used in swellstats
   */
  public isInvalidPropertyId(propertyId: string) {
    return this.invalidPropertyIds.includes(propertyId)
  }

  public get selectedViewState(): ViewSelectValue {
    return this._coreState.selectedView
  }

  public get allAccounts(): AccountSummary[] {
    return this._coreState.allAccounts || []
  }

  public get selectedAccount(): AccountSummary | null {
    if (!this._coreState?.selectedView?.account) return null

    return (
      this.allAccounts.find(
        (_) => _.id === this._coreState?.selectedView?.account
      ) || null
    )
  }

  public get selectProperty(): Property | null {
    if (!this.selectedAccount || !this._coreState?.selectedView?.property)
      return null

    return (
      this.selectedAccount.webProperties.find(
        (_) => _.id === this._coreState?.selectedView?.property
      ) || null
    )
  }

  public get selectedView(): View | null {
    if (!this.selectProperty || !this._coreState?.selectedView?.view)
      return null

    return (
      this.selectProperty.profiles.find(
        (_) => _.id === this._coreState?.selectedView?.view
      ) || null
    )
  }

  public get displayValues() {
    const { account, property, view } = this._coreState.selectedView
    let accountDisplay: string | undefined = undefined
    let accountSummary: AccountSummary | undefined = undefined
    let projectDisplay: string | undefined = undefined
    let webProperty: Property | undefined = undefined
    let viewDisplay: string | undefined = undefined

    if (account) {
      accountSummary = this._coreState.allAccounts?.find(
        (_) => _.id === account
      )
      accountDisplay = accountSummary?.name
    }

    if (accountSummary && property) {
      webProperty = accountSummary.webProperties.find((_) => _.id === property)
      projectDisplay = webProperty?.name
    }

    if (webProperty && view) {
      const selectedView = webProperty.profiles.find((_) => _.id === view)
      viewDisplay = selectedView?.name
    }

    return {
      accountDisplay,
      projectDisplay,
      viewDisplay,
    }
  }

  public setLoadData(allAccounts: AccountSummary[]): UserAccounts {
    return new UserAccounts({
      ...this._coreState,
      allAccounts: allAccounts || [],
      selectedView: defaultViewSelectValue,
      isInitializing: false,
    })
  }

  public setSelectedView(selectedView: ViewSelectValue): UserAccounts {
    return new UserAccounts({
      ...this._coreState,
      selectedView,
    })
  }
}
