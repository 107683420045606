import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import useAPIQuery, { APIValues } from './useAPIQuery'
import AnalyticsDataCollection from './AnalyticsData/AnalyticsDataCollection'
import { Metric, Channels } from '../../util/queryUtil'
import _noop from 'lodash/noop'
import useLocalStorageState from '../../Hooks/useLocalStorageState'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface DataProviderOwnProps {
  children: any
}

export type DataProviderProps = DataProviderOwnProps

export type Data = {
  isLoading: boolean
  data: AnalyticsDataCollection
}

export const DataContext = React.createContext<Data>({
  isLoading: true,
  data: AnalyticsDataCollection.initEmpty(),
})

export const DataQueryValuesContext = React.createContext<DataQueryValues>({
  metric: 'revenue',
  channel: 'all',
})

export type UpateDataQueryValues = (updatedValeus: APIValues) => void
export const UpdateDataQueryValuesContext = React.createContext<
  UpateDataQueryValues
>(_noop)

export type DataQueryValues = {
  metric: Metric
  channel: Channels | 'all'
}

const start = dayjs()
  .startOf('day')
  .subtract(366 * 2, 'days')

const end = dayjs().endOf('day')

const values: APIValues = {
  metric: 'revenue',
  channel: 'all',
}

const DataProvider = ({ children }: DataProviderProps) => {
  const [dataQueryValues, setDataQueryValues] = useLocalStorageState(
    'api-data-query-values',
    values
  )
  const { isLoading, result } = useAPIQuery(dataQueryValues, { start, end })

  return (
    <DataContext.Provider value={{ isLoading, data: result }}>
      <DataQueryValuesContext.Provider value={dataQueryValues}>
        <UpdateDataQueryValuesContext.Provider value={setDataQueryValues}>
          {children}
        </UpdateDataQueryValuesContext.Provider>
      </DataQueryValuesContext.Provider>
    </DataContext.Provider>
  )
}

export default React.memo(DataProvider)
