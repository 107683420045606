import React, { useMemo, useCallback } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Dayjs } from 'dayjs'
import { useData } from '../../Contexts/DataProvider/useDataAggregation'
import {
  isSameOrBefore,
  isSameOrAfter,
} from '../../Contexts/DataProvider/AnalyticsData/Selections'
import {
  sumProjection,
  meanProjection,
} from '../../Contexts/DataProvider/AnalyticsData/Projections'
import CalloutCardTemplate from '../../SharedComponents/Widgets/CalloutCard/CardTemplate'
import { mergeBy } from '../../Contexts/DataProvider/AnalyticsData/MergeBy'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      overflowX: 'auto',
      paddingTop: theme.spacing(2),
      flexWrap: 'wrap',
    },
    card: {
      marginRight: theme.spacing(2),
      width: 300,
      marginBottom: theme.spacing(2),
    },
  })
)

export interface StatsOwnProps {
  focusedDate: Dayjs
  cardClassName?: string
}

export type StatsProps = StatsOwnProps

const Stats = (props: StatsProps) => {
  const classes = useStyles()
  const { data } = useData()
  const startingCollection = useMemo(
    () => data.setAnchor(props.focusedDate.startOf('day')),
    [props.focusedDate, data]
  )

  const dayTotal = useMemo(
    () =>
      startingCollection
        .select(
          isSameOrAfter((_) => _.startOf('day')),
          isSameOrBefore((_) => _.endOf('day'))
        )
        .project(sumProjection)?.value || 0,
    [startingCollection]
  )

  const computeXPrevDayAverage = useCallback(
    (numDays) =>
      startingCollection
        .select(
          isSameOrAfter((_) => _.startOf('day').subtract(numDays, 'day')),
          isSameOrBefore((_) => _.endOf('day').subtract(1, 'day'))
        )
        .merge(mergeBy.day)
        .project(meanProjection)?.value || 0,
    [startingCollection]
  )

  const prevDayAvg = useMemo(() => computeXPrevDayAverage(1), [
    computeXPrevDayAverage,
  ])

  const sevenDayAvg = useMemo(() => computeXPrevDayAverage(7), [
    computeXPrevDayAverage,
  ])

  const fourteenDayAvg = useMemo(() => computeXPrevDayAverage(14), [
    computeXPrevDayAverage,
  ])

  const twentyOneDayAvg = useMemo(() => computeXPrevDayAverage(21), [
    computeXPrevDayAverage,
  ])

  return (
    <div className={classes.root}>
      <CalloutCardTemplate
        title={'Day Total'}
        callOutValue={dayTotal}
        className={clsx(classes.card, props.cardClassName)}
      />
      <CalloutCardTemplate
        title={'Prev Day'}
        callOutValue={prevDayAvg}
        progress={{
          numerator: dayTotal,
          denominator: prevDayAvg,
        }}
        className={clsx(classes.card, props.cardClassName)}
      />
      <CalloutCardTemplate
        title={'Prev 7 Day Avg'}
        callOutValue={sevenDayAvg}
        progress={{
          numerator: dayTotal,
          denominator: sevenDayAvg,
        }}
        className={clsx(classes.card, props.cardClassName)}
      />
      <CalloutCardTemplate
        title={'Prev 14 Day Avg'}
        callOutValue={fourteenDayAvg}
        progress={{
          numerator: dayTotal,
          denominator: fourteenDayAvg,
        }}
        className={clsx(classes.card, props.cardClassName)}
      />
      <CalloutCardTemplate
        title={'Prev 21 Day Avg'}
        callOutValue={twentyOneDayAvg}
        progress={{
          numerator: dayTotal,
          denominator: twentyOneDayAvg,
        }}
        className={clsx(classes.card, props.cardClassName)}
      />
    </div>
  )
}

export default React.memo(Stats)
