import React from 'react'
import numeral from 'numeral'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { colors } from '../../../theme'
import { useData } from '../../../Contexts/DataProvider/useDataAggregation'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    progressRoot: {
      flex: 1,
      minWidth: 0,
      borderColor: theme.palette.grey[200],
      height: 12,
      borderRadius: 6,
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: `1px solid ${theme.palette.divider}`,
    },
    progress: {
      height: '100%',
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      overflow: 'hideen',
    },
    icon: {
      width: 16,
      height: 16,
      color: theme.palette.text.secondary,
    },
  })
)

export interface ProgressBarOwnProps {
  target: number | null
  progress: number | null
  className?: string
}

export type ProgressBarProps = ProgressBarOwnProps

const ProgressBar = (props: ProgressBarProps) => {
  const classes = useStyles()

  let safeProgress = 0
  if (props.target !== null && props.progress !== null) {
    safeProgress = props.progress / props.target
  }

  const targetPercent = `${safeProgress * 100}%`
  const goalReached = safeProgress >= 1

  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      width: ['0%', targetPercent, targetPercent],
      opacity: [0, 1, 1],
      backgroundColor: [
        colors.contrast,
        colors.contrast,
        goalReached ? colors.opposite : colors.contrast,
      ],
    },
  }

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={clsx(classes.progressRoot)}>
        <motion.div
          key={`${props.target}-${props.progress}`}
          className={classes.progress}
          variants={variants}
          initial="hidden"
          animate={'visible'}
          transition={{ duration: 2, ease: 'easeInOut' }}
        />
      </div>
    </div>
  )
}

export default React.memo(ProgressBar)
