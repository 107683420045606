import { Row } from '../../../services/GoogleAPIService'
import AnalyticsDataPoint from './AnaltyicsDataPoint'
import { Range } from '../useAPIQuery'
import { ISelector, fillMissingPoints } from './Selections'
import { IMergeBy } from './MergeBy'
import { Projection } from './Projections'
import dayjs, { Dayjs } from 'dayjs'
import _uniqBy from 'lodash/uniqBy'
import { PersistedDataPoint, PersistedDataSet } from '../PersistedData'

export default class AnalyticsDataCollection {
  static initEmpty() {
    return new AnalyticsDataCollection([])
  }

  static initFromAPIData(rows: Row[] | null, dateRange: Range) {
    const parsed = (rows || [])
      .map((_) => AnalyticsDataPoint.initFromAPIDataPoint(_, dateRange))
      .filter((_) => _?.isValid())
    return new AnalyticsDataCollection(parsed)
  }

  static initFromPersistedDataset(persistedDataSet: PersistedDataSet | null) {
    return new AnalyticsDataCollection(
      (persistedDataSet?.datapoints || [])
        .map(AnalyticsDataPoint.initFromPersistedDataPoint)
        .filter((_) => _.isValid())
    )
  }

  public readonly dataPoints: AnalyticsDataPoint[]
  private _anchorDate: Dayjs

  constructor(dataPoints: AnalyticsDataPoint[], anchorDate: Dayjs = dayjs()) {
    this._anchorDate = anchorDate
    this.dataPoints = dataPoints.map(
      (_) => new AnalyticsDataPoint(_.date, _.value)
    )
  }

  setAnchor(anchorDate: Dayjs): AnalyticsDataCollection {
    return new AnalyticsDataCollection([...this.dataPoints], anchorDate)
  }

  clone(): AnalyticsDataCollection {
    return new AnalyticsDataCollection([...this.dataPoints], this._anchorDate)
  }

  select(...selectors: ISelector[]): AnalyticsDataCollection {
    const newCollection = selectors.reduce((dataPoints, selector) => {
      const newSet = selector(this._anchorDate, dataPoints)
      return newSet
    }, this.dataPoints)
    return new AnalyticsDataCollection(newCollection)
  }

  merge(mergeBy: IMergeBy): AnalyticsDataCollection {
    const newCollection = mergeBy(this.dataPoints)
    return new AnalyticsDataCollection(newCollection)
  }

  sort(): AnalyticsDataCollection {
    const copied = [...this.dataPoints]
    copied.sort((d1, d2) => d1.dateCompValue - d2.dateCompValue)
    return new AnalyticsDataCollection(copied, this._anchorDate)
  }

  project<TResult>(projection: Projection<TResult>): TResult {
    return projection(this.dataPoints)
  }

  dedupe(): AnalyticsDataCollection {
    // it is possible that unix is too precise to pick up overlaps.  revisit if so
    const copied = [...this.dataPoints]
    const deduped = _uniqBy(copied, (_) => _.dateCompValue)
    return new AnalyticsDataCollection(deduped, this._anchorDate)
  }

  get range(): Range {
    const sorted = this.sort()

    if (!sorted.dataPoints.length) return { start: dayjs(), end: dayjs() }

    return {
      start: sorted.dataPoints[0].date,
      end: sorted.dataPoints[sorted.dataPoints.length - 1].date,
    }
  }

  mergeWithNewAPIData(
    newAPIData: AnalyticsDataCollection
  ): AnalyticsDataCollection {
    const mergedDatapoints = [...newAPIData.dataPoints, ...this.dataPoints]
    return new AnalyticsDataCollection(mergedDatapoints, this._anchorDate)
      .dedupe()
      .sort()
      .select(fillMissingPoints(dayjs().endOf('day')))
  }

  get asPersistedPoints(): PersistedDataPoint[] {
    return this.dataPoints.map((_) => _.asPersistedDatapoint)
  }
}
