import useFormatValue from '../../Hooks/useFormatValue'

export type TickFormatter = (yValue: number) => string

const useYFormatter = (): TickFormatter => {
  const format = useFormatValue()
  return format
}

export default useYFormatter
