import React, { useMemo, useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Dayjs } from 'dayjs'
import { Bar } from '@nivo/bar'
import { useData } from '../../../Contexts/DataProvider/useDataAggregation'
import {
  padDaysAfter,
  padDaysBefore,
  isSameOrAfter,
  isSameOrBefore,
} from '../../../Contexts/DataProvider/AnalyticsData/Selections'
import {
  spanBucketProjection,
  xyProjections,
} from '../../../Contexts/DataProvider/AnalyticsData/Projections'
import GraphShell from '../../../SharedComponents/GraphShell'
import { colors } from '../../../theme'
import { DataQueryValuesContext } from '../../../Contexts/DataProvider'
import useFormatValue, {
  conditionallyRound,
} from '../../../Hooks/useFormatValue'
import { Typography } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 350,
    },
    label: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
  })
)

export interface MonthBreakdownOwnProps {
  panelDate: Dayjs
  selectedDate: Dayjs
  setSelectedDate: (newDate: Dayjs) => void
}

export type MonthBreakdownProps = MonthBreakdownOwnProps

const MonthBreakdown = (props: MonthBreakdownProps) => {
  const classes = useStyles()
  const metric = useContext(DataQueryValuesContext)?.metric
  const { data } = useData()
  const graphData = useMemo(
    () =>
      data
        .setAnchor(props.panelDate)
        .select(
          padDaysBefore(31),
          padDaysAfter(31),
          isSameOrAfter((_) => _.startOf('month')),
          isSameOrBefore((_) => _.endOf('month'))
        )
        .project(spanBucketProjection('day'))
        .project(xyProjections(props.panelDate.startOf('month'), 'day'))
        .map((_) => ({ day: _.x, [metric]: conditionallyRound(_.y) })),
    [data, props.panelDate, metric]
  )

  return (
    <>
      <Typography
        variant="body1"
        className={classes.label}
        color="textSecondary"
      >
        {props.panelDate.format('MMMM YYYY:')}
      </Typography>
      <GraphShell
        className={classes.root}
        renderGraph={({ width, height }) => {
          return (
            <Bar
              data={graphData}
              width={width}
              height={height}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              keys={[metric]}
              colors={(_) => {
                return props.selectedDate.date() - 1 === _.index
                  ? colors.contrast
                  : colors.opposite
              }}
              indexBy="day"
              enableLabel={false}
              axisLeft={{
                tickSize: 0,
                format: () => '',
              }}
              axisBottom={{
                tickSize: 0,
                format: () => '',
              }}
              onClick={(_) =>
                props.setSelectedDate(props.panelDate.date(_.index + 1))
              }
            />
          )
        }}
      />
    </>
  )
}

export default React.memo(MonthBreakdown)
