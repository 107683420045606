import { WindowConfig } from '../../util/windowingUtils'

const currentLabels = {
  day: 'Today',
  week: 'This Week',
  month: 'This Month',
  year: 'This Year',
}

const previousLabels = {
  day: 'Yesterday',
  week: 'Last Week',
  month: 'Last Month',
  year: 'Last Year',
}

const useGetWindowLabel = () => (windowConfig: WindowConfig): string => {
  if (windowConfig.rangeIterations === 0)
    return currentLabels[windowConfig.span]

  if (windowConfig.frame === 'year' && windowConfig.rangeIterations === 1)
    return `${currentLabels[windowConfig.span]} - LY`

  return previousLabels[windowConfig.span]
}

export default useGetWindowLabel
