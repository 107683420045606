import AnalyticsDataPoint from './AnaltyicsDataPoint'
import dayjs, { Dayjs } from 'dayjs'
import _minBy from 'lodash/minBy'
import _maxBy from 'lodash/maxBy'
import { DATA_HOUR_RESOLUTION } from '../../../util/constants'
import {
  getWeekOfMonth,
  getWeekOfYear,
  isSameOrAfterUtil,
  isSameOrBeforeUtil,
  isAfterUtil,
  isBeforeUtil,
  getSpanHourValue,
} from '../../../util/extraDayJsUtils'
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfterPlugin)
dayjs.extend(isSameOrBeforePlugin)

export type ISelector = (
  anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => AnalyticsDataPoint[]

type DayjsOp = (dayjs: Dayjs) => boolean
const applyDayjsOp = (
  dataPoints: AnalyticsDataPoint[],
  dayjsOp: DayjsOp
): AnalyticsDataPoint[] => dataPoints.filter((_) => dayjsOp(_.date))

/**
 * gets the dates after the current calendar block. If the first of the month starts on a Monday, Sunday of the previous month is added
 */
export const afterCalendarBlockStart: ISelector = (
  anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => {
  const startOfMonth = anchorDate.startOf('month')
  const startOfCalendarBlock = startOfMonth
    .subtract(startOfMonth.weekday(), 'days')
    .startOf('day')
  return applyDayjsOp(dataPoints, (_) =>
    isSameOrAfterUtil(_, startOfCalendarBlock)
  )
}

/**
 * gets the dates before the current calendar block ends. If the last of the month starts on a Friday, Saturday of the next month is added
 */
export const beforeCalendarBlockEnd: ISelector = (
  anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => {
  const endOfMonth = anchorDate.endOf('month')
  const startOfCalendarBlock = endOfMonth
    .add(6 - endOfMonth.weekday(), 'days')
    .endOf('day')
  return applyDayjsOp(dataPoints, (_) => _.isSameOrBefore(startOfCalendarBlock))
}

/**
 * Conditionally pads empty data dates before anchor date if value is empty
 */
export const padDaysBefore = (numDays: number): ISelector => (
  anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => {
  const numDataPoints = (24 / DATA_HOUR_RESOLUTION) * numDays
  const padPoints: AnalyticsDataPoint[] = []
  for (let i = numDataPoints; i > 0; i--) {
    const padDate = anchorDate.subtract(i * DATA_HOUR_RESOLUTION, 'hours')
    padPoints.push(new AnalyticsDataPoint(padDate, 0))
  }
  const currentMinDataPoint = _minBy(dataPoints, (_) => _.date.unix())
  const padCollection: AnalyticsDataPoint[] = []
  padPoints.forEach((_) => {
    if (
      currentMinDataPoint?.date &&
      isBeforeUtil(_.date, currentMinDataPoint.date)
    ) {
      padCollection.push(_)
    }
  })
  return [...padCollection, ...dataPoints]
}

/**
 * Conditionally pads empty data dates after anchor date if value is empty
 */
export const padDaysAfter = (numDays: number): ISelector => (
  anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => {
  const numDataPoints = (24 / DATA_HOUR_RESOLUTION) * numDays
  const padPoints: AnalyticsDataPoint[] = []
  for (let i = 0; i < numDataPoints; i++) {
    const padDate = anchorDate.add(i * DATA_HOUR_RESOLUTION, 'hours')
    padPoints.push(new AnalyticsDataPoint(padDate, 0))
  }
  const curentMaxDataPoint = _maxBy(dataPoints, (_) => _.date.unix())
  const padCollection: AnalyticsDataPoint[] = []
  padPoints.forEach((_) => {
    if (
      curentMaxDataPoint?.date &&
      isAfterUtil(_.date, curentMaxDataPoint.date)
    ) {
      padCollection.push(_)
    }
  })
  return [...dataPoints, ...padCollection]
}

export type Span = 'day' | 'week' | 'month' | 'year'

const relativeCompare = (
  num: number,
  span: Span,
  beforeAfter: 'before' | 'after',
  nextPrevious: 'next' | 'previous'
): ISelector => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    let compDate = anchorDate
    compDate =
      nextPrevious === 'previous'
        ? compDate.subtract(num, span)
        : compDate.add(num, span)
    compDate = compDate.startOf('day')
    return applyDayjsOp(dataPoints, (_) =>
      beforeAfter === 'before'
        ? isSameOrBeforeUtil(compDate, _)
        : isSameOrAfterUtil(compDate, _)
    )
  }
}

export const beforePrevious = (num: number, span: Span): ISelector =>
  relativeCompare(num, span, 'before', 'previous')
export const afterPrevious = (num: number, span: Span): ISelector =>
  relativeCompare(num, span, 'after', 'previous')
export const beforeNext = (num: number, span: Span): ISelector =>
  relativeCompare(num, span, 'before', 'next')
export const afterNext = (num: number, span: Span): ISelector =>
  relativeCompare(num, span, 'after', 'next')

export type RelativeSpan = 'hour' | 'weekday' | 'dayOfMonth' | 'dayOfYear'

export const beforeRelative = (span: RelativeSpan): ISelector => (
  anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => {
  const comp = {
    hour: (_: Dayjs) => _.hour() <= anchorDate.hour(),
    weekday: (_: Dayjs) => _.weekday() <= anchorDate.weekday(),
    dayOfMonth: (_: Dayjs) => _.date() <= anchorDate.date(),
    dayOfYear: (_: Dayjs) => _.dayOfYear() <= anchorDate.dayOfYear(),
  }
  return applyDayjsOp(dataPoints, comp[span])
}

const relativeSpanCapture = (
  num: number,
  span: Span,
  previousNext: 'previous' | 'next'
): ISelector => (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
  const start = anchorDate.startOf(span).subtract(num, span)
  const end = start.endOf(span)
  return applyDayjsOp(
    dataPoints,
    (_) => isSameOrAfterUtil(_, start) && isSameOrBeforeUtil(_, end)
  )
}

export const previous = (span: Span, jumpSpans: number) =>
  relativeSpanCapture(jumpSpans, span, 'previous')

export const next = (span: Span, jumpSpans: number) =>
  relativeSpanCapture(jumpSpans, span, 'next')

export const afterWindowedRange = (
  range: Span,
  rangeIterations: number
): ISelector => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const cutoffDate = anchorDate

      .subtract(rangeIterations, range)
      .startOf(range)
    return applyDayjsOp(dataPoints, (_) => isSameOrAfterUtil(_, cutoffDate))
  }
}

export const beforeWindowedRange = (
  range: Span,
  rangeIterations: number
): ISelector => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    let cutoffDate = anchorDate
    if (rangeIterations > 0) {
      // if range itrations is 0, that means the window is the current range.  Subtracting a range would grab previous not current
      cutoffDate = cutoffDate.subtract(1, range)
    }

    cutoffDate = cutoffDate.endOf(range)
    return applyDayjsOp(dataPoints, (_) => isSameOrBeforeUtil(_, cutoffDate))
  }
}

export const notInFuture: ISelector = (
  _anchorDate: Dayjs,
  dataPoints: AnalyticsDataPoint[]
) => {
  const cutoffDate = dayjs()
  return applyDayjsOp(dataPoints, (_) => isSameOrBeforeUtil(_, cutoffDate))
}

export const selectMatchingFrame = (span: Span, frame: Span): ISelector => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const isInFrame = (compDate: Dayjs): boolean => {
      if (span === 'day') {
        if (frame === 'day') {
          return true
        } else if (frame === 'week') {
          return anchorDate.weekday() === compDate.weekday()
        } else if (frame === 'month') {
          return anchorDate.date() === compDate.date()
        } else if (frame === 'year') {
          return anchorDate.dayOfYear() === compDate.dayOfYear()
        }
      } else if (span === 'week') {
        if (frame === 'day') {
          return false
        } else if (frame === 'week') {
          return true
        } else if (frame === 'month') {
          return getWeekOfMonth(anchorDate) === getWeekOfMonth(compDate)
        } else if (frame === 'year') {
          return getWeekOfYear(anchorDate) === getWeekOfYear(compDate)
        }
      } else if (span === 'month') {
        if (frame === 'day') {
          return false
        } else if (frame === 'week') {
          return false
        } else if (frame === 'month') {
          return true
        } else if (frame === 'year') {
          return anchorDate.month() === compDate.month()
        }
      } else if (span === 'year') {
        if (frame === 'day') {
          return false
        } else if (frame === 'week') {
          return false
        } else if (frame === 'month') {
          return false
        } else if (frame === 'year') {
          return true
        }
      } else {
        console.error('Received invalid frame params', { span, frame })
      }

      return false
    }

    return applyDayjsOp(dataPoints, (_) => isInFrame(_))
  }
}

/**
 * Force the ref to be cutoff to the current time in frame.
 * Example, if comparing current day to yesterday, only select up to the current time of day
 */
export const selectBeforeRelativeFrame = (frame: Span): ISelector => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const anchorDateHourValue = getSpanHourValue(anchorDate, frame)
    const isBeforeRelativeFrame = (compDate: Dayjs): boolean => {
      return getSpanHourValue(compDate, frame) <= anchorDateHourValue
    }

    return applyDayjsOp(dataPoints, (_) => isBeforeRelativeFrame(_))
  }
}

export type GenerateCutoffFromAnchor = (anchor: Dayjs) => Dayjs
export const isSameOrAfter = (generateCutoff: GenerateCutoffFromAnchor) => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const anchorToUse = generateCutoff(anchorDate)

    return applyDayjsOp(dataPoints, (_) => isSameOrAfterUtil(_, anchorToUse))
  }
}

export const isSameOrBefore = (generateCutoff: GenerateCutoffFromAnchor) => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const anchorToUse = generateCutoff(anchorDate)

    return applyDayjsOp(dataPoints, (_) => isSameOrBeforeUtil(_, anchorToUse))
  }
}

export const isBefore = (generateCutoff: GenerateCutoffFromAnchor) => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const anchorToUse = generateCutoff(anchorDate)

    return applyDayjsOp(dataPoints, (_) => isBeforeUtil(_, anchorToUse))
  }
}

/**
 * Adds missing datapoints between data start and end date with 0 values
 */
export const fillMissingPoints = (endDate: Dayjs) => {
  return (anchorDate: Dayjs, dataPoints: AnalyticsDataPoint[]) => {
    const startDP = dataPoints[0]
    if (!startDP) return []

    const expectedBucketCount =
      Math.floor(endDate.diff(startDP.date, 'hours') / DATA_HOUR_RESOLUTION) + 1

    let dpCursor = 0
    const returnData: AnalyticsDataPoint[] = []
    for (
      let filledIndex = 0;
      filledIndex < expectedBucketCount;
      filledIndex++
    ) {
      const expectedDate = startDP.date.add(
        filledIndex * DATA_HOUR_RESOLUTION,
        'hours'
      )
      const cursortValue = dataPoints[dpCursor]
      if (!cursortValue || !expectedDate.isSame(cursortValue.date, 'hour')) {
        returnData.push(new AnalyticsDataPoint(expectedDate, 0))
      } else {
        returnData.push(cursortValue)
        dpCursor++
      }
    }

    return returnData
  }
}
