import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  UserAccountsContext,
  useUpdateSelectedView,
} from '../../SharedComponents/AccountInitialization/Contexts'
import AccountSection from './AccountSection'
import LabeledSection from './LabeledSection'
import LabeledContent from './LabeledContent'
import ViewSelector from '../../SharedComponents/ViewSelector'
import useToggle from '../../Hooks/useToggle'
import { ViewSelectValue } from '../../SharedComponents/AccountInitialization/UserAccounts'
import AntIconButton from '../../SharedComponents/AntIconButton'
import { Box } from '@material-ui/core'
import { Database } from 'react-feather'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    contentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    viewDataSection: {
      marginRight: theme.spacing(6),
    },
  })
)

export interface ViewSectionOwnProps {
  className?: string
}

export type ViewSectionProps = ViewSectionOwnProps

const DataSourceSection = (props: ViewSectionProps) => {
  const userAccounts = useContext(UserAccountsContext)
  const updateSelectedView = useUpdateSelectedView()
  const [isPromptOpen, openPrompt, closePrompt] = useToggle(false)
  const classes = useStyles()
  const {
    accountDisplay,
    projectDisplay,
    viewDisplay,
  } = userAccounts.displayValues

  return (
    <>
      <AccountSection label="Data Source" className={props.className}>
        <div className={classes.contentContainer}>
          <LabeledSection className={classes.viewDataSection} label={'Account'}>
            <LabeledContent content={accountDisplay as string} />
          </LabeledSection>
          <LabeledSection
            className={classes.viewDataSection}
            label={'Property'}
          >
            <LabeledContent content={projectDisplay as string} />
          </LabeledSection>
          <LabeledSection className={classes.viewDataSection} label={'View'}>
            <LabeledContent content={viewDisplay as string} />
          </LabeledSection>
        </div>
        <Box width="100%" display="flex" justifyContent="center" marginTop={2}>
          <AntIconButton
            Icon={Database}
            size="large"
            type="primary"
            onClick={openPrompt}
          >
            {`Update Data Source`}
          </AntIconButton>
        </Box>
      </AccountSection>
      <ViewSelector
        value={userAccounts.selectedViewState}
        onChange={(newValue: ViewSelectValue) => {
          closePrompt()
          updateSelectedView(newValue)
        }}
        isOpen={isPromptOpen}
        onClose={() => closePrompt()}
      />
    </>
  )
}

export default React.memo(DataSourceSection)
