import React, { useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Line } from '@nivo/line'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useData } from '../../Contexts/DataProvider/useDataAggregation'
import { Dayjs } from 'dayjs'
import {
  isSameOrAfter,
  isSameOrBefore,
  padDaysBefore,
  padDaysAfter,
  notInFuture,
} from '../../Contexts/DataProvider/AnalyticsData/Selections'
import {
  xyProjections,
  accumulate,
} from '../../Contexts/DataProvider/AnalyticsData/Projections'
import useFormatValue from '../../Hooks/useFormatValue'
import { colors } from '../../theme'
import GraphLoader from '../../SharedComponents/GraphLoader'
import Paper from '../../SharedComponents/Paper'
import { Typography } from '@material-ui/core'
import { roundDateToPreviousHourResolution } from '../../util/windowingUtils'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 320,
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
    },
    graphContainer: {
      flex: 1,
      minHeight: 0,
      width: '100%',
    },
  })
)

export type GraphConfig = {}

export interface PerformanceOwnProps {
  focusedDate: Dayjs
}

export type PerformanceProps = PerformanceOwnProps

const Performance = (props: PerformanceProps) => {
  const classes = useStyles({})
  const { isLoading, data } = useData()
  const formatValue = useFormatValue()
  const graphData = useMemo(
    () => [
      {
        data: data
          .setAnchor(roundDateToPreviousHourResolution(props.focusedDate))
          .select(
            isSameOrAfter((_) => _.startOf('day')),
            isSameOrBefore((_) => _.endOf('day')),
            padDaysBefore(1),
            padDaysAfter(1),
            isSameOrAfter((_) => _.startOf('day')),
            isSameOrBefore((_) => _.endOf('day')),
            notInFuture
          )
          .project(accumulate)
          .project(xyProjections(props.focusedDate.startOf('day'))),
        id: `Day's Performance`,
      },
    ],
    [data, props.focusedDate]
  )

  const xFormatter = (hourValue: number) =>
    props.focusedDate.startOf('day').add(hourValue, 'hour').format('h a')

  return (
    <Paper className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="body1" color="textSecondary">
          {"Day's Performance"}
        </Typography>
      </div>
      <div className={classes.graphContainer}>
        <GraphLoader isLoading={isLoading}>
          <AutoSizer>
            {({ width, height }) => (
              <Line
                key={props.focusedDate?.format?.('MM/DD/YYYY')}
                colors={[colors.contrast]}
                width={width}
                height={height}
                data={graphData}
                xScale={{
                  type: 'linear',
                }}
                yScale={{
                  type: 'linear',
                }}
                // animate={true}
                enableSlices="x"
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 40,
                  left: 60,
                }}
                enableGridX={false}
                axisBottom={{
                  format: xFormatter as any,
                  legendPosition: 'middle',
                  legendOffset: 40,
                }}
                axisLeft={{
                  format: formatValue as any,
                }}
                xFormat={xFormatter as any}
                yFormat={formatValue as any}
              />
            )}
          </AutoSizer>
        </GraphLoader>
      </div>
    </Paper>
  )
}

export default React.memo(Performance)
