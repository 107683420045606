import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Paper from '../../../SharedComponents/Paper'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
)

export interface ContainerOwnProps {
  title: string
  className?: string
  children: any
}

export type ContainerProps = ContainerOwnProps

const Container = (props: ContainerProps) => {
  const classes = useStyles()

  return (
    <Paper className={clsx(classes.root, props.className)}>
      {props.children}
    </Paper>
  )
}

export default React.memo(Container)
