import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { motion, Variants } from 'framer-motion'
import dayjs from 'dayjs'
import CalendarPicker from '../CalendarPicker'
import { Typography } from '@material-ui/core'
import MonthBreakdown from './MonthBreakdown'
import Performance from '../Performance'
import { boxShadows } from '../../../theme'
import Stats from '../Stats'
import GlobalFilterButton from '../../../SharedComponents/GlobalFilterButton'
import FullTable from '../IsoWeekBreakdown/FullTable'
import FreeTrialWarning from '../../../SharedComponents/FreeTrialWarning'
import SubscriptionGaurd from '../../../SharedComponents/SubscriptionGaurd'
import SwitchMetricPrompt from '../../../SharedComponents/SwitchMetricPrompt'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      overflowY: 'auto',
    },
    calendarContainer: {
      width: 400,
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      boxShadow: boxShadows[1],
    },
    headerContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
    headerText: {
      fontWeight: 'bold',
    },
    contentContainer: {
      flex: 1,
      padding: theme.spacing(3),
      overflowX: 'hidden',
    },
    switchMetricPrompt: {
      marginTop: theme.spacing(2),
    },
  })
)

export interface HomeOwnProps {}

export type HomeProps = HomeOwnProps
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 0.5,
    },
  },
} as Variants

const Home = (props: HomeProps) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState(() => dayjs())
  const [panelDate, setPanelDate] = useState(() => dayjs())

  const interceptSetPanelDate = (val: any) => {
    setPanelDate(val)
  }
  return (
    <SubscriptionGaurd>
      <div className={classes.root}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeInOut', delay: 0.4 }}
          className={classes.contentContainer}
        >
          <div className={classes.headerContainer}>
            <Typography variant="h5" className={classes.headerText}>
              {selectedDate.format('dddd, MMMM Do YYYY')}
            </Typography>
            <GlobalFilterButton />
          </div>
          <FreeTrialWarning />
          <SwitchMetricPrompt className={classes.switchMetricPrompt} />
          <Stats focusedDate={selectedDate} />
          <FullTable focusedDay={selectedDate} />
          <Performance focusedDate={selectedDate} />
        </motion.div>
        <motion.div
          initial={{ x: 500 }}
          animate={{ x: 0 }}
          transition={{
            ease: 'easeInOut',
          }}
          className={classes.calendarContainer}
        >
          <CalendarPicker
            value={selectedDate}
            onChange={setSelectedDate}
            onPanelChange={interceptSetPanelDate}
          />
          <MonthBreakdown
            panelDate={panelDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </motion.div>
      </div>
    </SubscriptionGaurd>
  )
}

export default React.memo(Home)
