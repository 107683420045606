import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Paper from '../../../SharedComponents/Paper'
import GraphV2, {
  GraphConfig,
  colorSets,
} from '../../../SharedComponents/GraphV2'
import {
  ComparisonValue,
  getShortText,
  options,
  valueToWindowsConfig,
} from '../../../util/comparisonUtil'
import { Typography, Link } from '@material-ui/core'
import { Cascader } from 'antd'
import { Resolution } from '../../../util/queryUtil'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
    },
    graphContainer: {
      flex: 1,
      width: '100%',
    },
  })
)

export interface GraphOwnProps {
  className?: string
}

export type GraphProps = GraphOwnProps

const defaultComparisonValue: ComparisonValue = ['day', 'day']

const Graph = (props: GraphProps) => {
  const [comparisonValue, setComparisonValue] = useState(defaultComparisonValue)
  const classes = useStyles()
  const graphConfig: GraphConfig = (() => {
    const defaultResolution: Resolution = {
      day: 'hour',
      week: 'day',
      month: 'day',
      year: 'week',
    }[comparisonValue[0]] as Resolution

    return {
      resolution: defaultResolution,
      windows: valueToWindowsConfig(comparisonValue),
    } as GraphConfig
  })()
  const [source, comp] = getShortText(comparisonValue)
  return (
    <Paper className={clsx(classes.root, props.className)} elevation={1}>
      <div className={classes.headerContainer}>
        <Typography variant="body1" color="textSecondary">
          {`${source} vs ${comp}`}
        </Typography>
        <Cascader
          options={options}
          onChange={(_) => setComparisonValue(_ as ComparisonValue)}
        >
          <Link href="#">{'update comparison'}</Link>
        </Cascader>
      </div>
      <div className={classes.graphContainer}>
        <GraphV2
          config={graphConfig}
          overrideColors={colorSets.simpleComparison}
          overrideLabels={[source, comp]}
        />
      </div>
    </Paper>
  )
}

export default React.memo(Graph)
