export default {
  app: {
    login: '/login',
    home: '/',
    runningTotal: '/running-total',
    calendar: '/calendar',
    account: '/account',
  },
  googleAPI: {
    accountSummaries:
      'https://content.googleapis.com/analytics/v3/management/accountSummaries',
    reports:
      'https://analyticsreporting.googleapis.com/v4/reports:batchGet?alt=json',
  },
}
