import { APIValuesAndView } from './useAPIQuery'
import { APIQueryDateDimension, APIQueryDateRange } from './PersistedData'
import { Report } from '../../services/GoogleAPIService'
import { Channels } from '../../util/queryUtil'
import { Dimension, MetricId } from '../../util/GAPIRequestTypes'

const channelSegmentIds = {
  referral: 'gaid::-8',
  direct: 'gaid::-7',
  organic: 'gaid::-5',
  paid: 'gaid::-4',
  email: 'sessions::condition::ga:medium==email',
  other: 'gaid::-8', // TODO: this needs to be updated and is not right
}

export default function buildQuery(
  apiValuesAndView: APIValuesAndView,
  dateRange: APIQueryDateRange,
  dimenion: APIQueryDateDimension
): Report {
  const generateChannelDimension = (
    channel: 'all' | Channels
  ): Dimension | null => {
    if (channel === 'all') return null

    return {
      name: 'ga:segment',
    }
  }

  let selectedMetric: MetricId = 'ga:transactions'

  if (apiValuesAndView.metric === 'revenue') {
    selectedMetric = 'ga:transactionRevenue'
  } else if (apiValuesAndView.metric === 'sessions') {
    selectedMetric = 'ga:sessions'
  } else {
    selectedMetric = 'ga:transactions'
  }

  const withoutSegment: any = {
    viewId: apiValuesAndView.viewId,
    dateRanges: dateRange,
    metrics: [
      {
        expression: selectedMetric,
      },
    ],
    dimensions: [
      dimenion,
      generateChannelDimension(apiValuesAndView.channel),
    ].filter(Boolean),
    orderBys: [
      {
        fieldName: 'ga:nthHour',
        orderType: 'HISTOGRAM_BUCKET',
        sortOrder: 'ASCENDING',
      },
    ],
  }

  if (apiValuesAndView.channel !== 'all') {
    withoutSegment.segments = [
      {
        segmentId: channelSegmentIds[apiValuesAndView.channel],
      },
    ]
  }

  return withoutSegment
}
