import AnalyticsDataCollection from './AnalyticsData/AnalyticsDataCollection'
import { useContext, useMemo } from 'react'
import { DataContext } from '.'
import { Dayjs } from 'dayjs'
import { getCurrentReferenceDate } from '../../util/windowingUtils'

export type DataAggregation<TResult> = (
  data: AnalyticsDataCollection
) => TResult

const useDataAggregation = <TResult>(
  aggregation: DataAggregation<TResult>
): { data: TResult; isLoading: boolean } => {
  const { data, isLoading } = useContext(DataContext)
  const result = useMemo(() => aggregation(data), [aggregation, data])
  return {
    data: result,
    isLoading,
  }
}

export default useDataAggregation

export const useData = (): {
  data: AnalyticsDataCollection
  isLoading: boolean
} => {
  return useContext(DataContext)
}

export const useDataBounds = (): [Dayjs, Dayjs] => {
  const { data, isLoading } = useData()

  return useMemo(() => {
    const defaultBounds = [
      getCurrentReferenceDate(),
      getCurrentReferenceDate(),
    ] as [Dayjs, Dayjs]

    if (isLoading) return defaultBounds

    const sorted = data.sort()
    if (!sorted.dataPoints.length) return defaultBounds

    return [
      sorted.dataPoints[0].date,
      sorted.dataPoints[sorted.dataPoints.length - 1].date,
    ] as [Dayjs, Dayjs]
  }, [data, isLoading])
}
