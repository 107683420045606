import React, { useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Line } from '@nivo/line'
import AutoSizer from 'react-virtualized-auto-sizer'
import { LegendAnchor, LegendDirection } from '@nivo/legends'
import useIsMobile from '../../Hooks/useIsMobile'
import {
  getReferenceDate,
  evaluateWindow,
  WindowConfig,
} from '../../util/windowingUtils'
import { useData } from '../../Contexts/DataProvider/useDataAggregation'
import GraphLoader from '../GraphLoader'
import {
  xyProjections,
  accumulate,
} from '../../Contexts/DataProvider/AnalyticsData/Projections'
import useXFormatter from './useXFormatter'
import useGetWindowLabel from './useGetWindowLabel'
import { mergeBy } from '../../Contexts/DataProvider/AnalyticsData/MergeBy'
import useYFormatter from './useYFormatter'
import { colors } from '../../theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    showAggContainer: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mobileShowAgg: {
      padding: theme.spacing(2),
    },
    toggleRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    graphContainer: {
      minHeight: 300,
      flex: 1,
      maxHeight: '70vh',
      margin: theme.spacing(1),
    },
  })
)

export type GraphConfig = {
  resolution: GraphResolution
  windows: WindowConfig[]
}

export interface RunningStatsOwnProps {
  config: GraphConfig
  overrideColors?: string[]
  overrideLabels?: string[]
}

export type RunningStatsProps = RunningStatsOwnProps

export type GraphResolution = 'hour' | 'day' | 'month' | 'week'

export const colorSets = {
  simpleComparison: [colors.contrast, colors.darkGray],
}

const RunningStats = (props: RunningStatsProps) => {
  const { data } = useData()
  const getWindowLabel = useGetWindowLabel()
  const graphKey = useMemo(
    () => `${JSON.stringify(props.config)}-${data.dataPoints.length}`,
    [props.config, data]
  )
  const graphData = useMemo(
    () =>
      props.config.windows.map((_, index) => {
        const referenceDate = getReferenceDate(_)
        return {
          data: evaluateWindow(_, referenceDate, data)
            .merge(mergeBy[props.config.resolution])
            .project(accumulate)
            .project(xyProjections(getReferenceDate(_))),
          id: props.overrideLabels?.[index] || getWindowLabel(_),
        }
      }),
    [
      data,
      getWindowLabel,
      props.config.resolution,
      props.config.windows,
      props.overrideLabels,
    ]
  )

  const xFormatter = useXFormatter(
    props.config.resolution,
    props.config.windows
  )
  const yFormatter = useYFormatter()

  return (
    <GraphLoader isLoading={false}>
      <AutoSizer>
        {({ width, height }) => (
          <Line
            key={graphKey}
            colors={props.overrideColors || { scheme: 'set1' }}
            width={width}
            height={height}
            data={graphData}
            xScale={{
              type: 'linear',
            }}
            yScale={{
              type: 'linear',
            }}
            // animate={true} this just seems to cause to many issues/get caught in weird display states
            enableSlices="x"
            margin={{
              top: 20,
              right: 20,
              bottom: 60,
              left: 60,
            }}
            enableGridX={false}
            axisBottom={{
              format: xFormatter as any,
              legendPosition: 'middle',
              legendOffset: 40,
            }}
            axisLeft={{
              format: yFormatter as any,
            }}
            xFormat={xFormatter as any}
            yFormat={yFormatter as any}
          />
        )}
      </AutoSizer>
    </GraphLoader>
  )
}

export default React.memo(RunningStats)
