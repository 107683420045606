import React from 'react'
import _noop from 'lodash/noop'

export type AuthResponse = {
  additionalUserInfo: {
    isNewUser: boolean
    profile: {
      email: string
      family_name: string
      given_name: string
      granted_scopes: string
      id: string
      locale: string
      name: string
      picture: string
      verified_email: boolean
    }
  }
  credential: {
    oauthAccessToken: string
    oauthIdToken: string
    providerId: 'google.com'
    signInMethod: 'google.com'
  }
  user: {
    email: string
    displayName: string
    createdAt: string
    uid: string
  }
}

export type Auth = AuthResponse | null

export const AuthContext = React.createContext<Auth>(null)

export type UpdateAuth = (loginRespone: AuthResponse | null) => void

export const UpdateAuthContext = React.createContext<UpdateAuth>(_noop)
