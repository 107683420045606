import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Button, { BaseButtonProps, ButtonProps } from 'antd/lib/button/button'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
)

export interface AntIconButtonOwnProps {
  Icon: React.ComponentType<any>
}

export type AntIconButtonProps = AntIconButtonOwnProps &
  BaseButtonProps &
  ButtonProps

const AntIconButton = ({ className, Icon, ...toPass }: AntIconButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      className={clsx(classes.root, className)}
      icon={<Icon className={classes.icon} />}
      {...toPass}
    />
  )
}

export default React.memo(AntIconButton)
