import React, { useContext, useMemo, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  UpdateUserAccountsContext,
  UserAccountsContext,
  usePersistedViewSelectValue,
} from './Contexts'
import ViewSelector from '../ViewSelector'
import UserAccounts, { defaultViewSelectValue } from './UserAccounts'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    brandedText: { marginBottom: theme.spacing(1) },
    description: {
      marginBottom: theme.spacing(4),
    },
  })
)

export interface ViewSelectPromptOwnProps {}

export type ViewSelectPromptProps = ViewSelectPromptOwnProps

const ViewSelectPrompt = (props: ViewSelectPromptProps) => {
  const classes = useStyles()
  const updateUserAccounts = useContext(UpdateUserAccountsContext)
  const userAccounts = useContext(UserAccountsContext)
  const [persistedValues, setPersistedValues] = usePersistedViewSelectValue()

  const isPersistedValid = UserAccounts.isValidView(
    userAccounts,
    persistedValues
  )

  const initialValues = useMemo(
    () => (isPersistedValid ? persistedValues : defaultViewSelectValue),
    []
  )

  useEffect(() => {
    if (isPersistedValid) {
      updateUserAccounts(userAccounts.setSelectedView(persistedValues))
    }
  }, [isPersistedValid])

  return (
    <ViewSelector
      isOpen
      value={initialValues}
      onChange={(newValue) => setPersistedValues(newValue)}
      dismissDisabled
    />
  )
}

export default React.memo(ViewSelectPrompt)
