import { useAlert } from '../Notifications'
import { RequestTriggers } from '../services/types'

export default function useAlertRequestTriggers(
  successMessage: string | null,
  errorMessage: string | null
): RequestTriggers {
  const alert = useAlert()

  const returnVal: RequestTriggers = {}

  if (successMessage)
    returnVal.onSuccess = () => alert('success', successMessage)

  if (errorMessage) returnVal.onFailure = () => alert('error', errorMessage)

  return returnVal
}
