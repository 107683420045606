import React from 'react'
import useIsAuthenticated from './Hooks/useIsAuthenticated'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './Pages/Login'
import routes from './util/routes'
import ComingSoon from './SharedComponents/ComingSoon'
import Account from './Pages/Account'
import LayoutSwitch from './SharedComponents/LayoutSwitch'
import DesktopAppContainer from './SharedComponents/DesktopAppContainer'
import MobileAppContainer from './SharedComponents/MobileAppContainer'
import DesktopHome from './Pages/Home/Desktop'
import MobileHome from './Pages/Home/Mobile'
import DesktopCalendar from './Pages/Calendar/Desktop'
import MobileCalendar from './Pages/Calendar/Mobile'

export interface RoutesOwnProps {}

export type RoutesProps = RoutesOwnProps

const Routes = (props: RoutesProps) => {
  const isAuthenticated = useIsAuthenticated()

  return (
    <Switch>
      <Route exact path={routes.app.login}>
        <Login />
      </Route>
      {!isAuthenticated ? (
        <Redirect to={routes.app.login} />
      ) : (
        <LayoutSwitch
          mobile={(children) => (
            <MobileAppContainer>{children}</MobileAppContainer>
          )}
          desktop={(children) => (
            <DesktopAppContainer>{children}</DesktopAppContainer>
          )}
        >
          <Switch>
            <Route exact path={routes.app.home}>
              <LayoutSwitch
                mobile={(children) => <MobileHome />}
                desktop={(children) => <DesktopHome />}
              />
            </Route>
            <Route exact path={routes.app.calendar}>
              <LayoutSwitch
                mobile={() => <MobileCalendar />}
                desktop={(children) => <DesktopCalendar />}
              />
            </Route>
            <Route exact path={routes.app.account}>
              <Account />
            </Route>
            <Redirect to={routes.app.home} />
          </Switch>
        </LayoutSwitch>
      )}
    </Switch>
  )
}

export default React.memo(Routes)
