import dayjs, { Dayjs } from 'dayjs'
import AnalyticsDataCollection from '../Contexts/DataProvider/AnalyticsData/AnalyticsDataCollection'
import {
  afterWindowedRange,
  beforeWindowedRange,
  selectMatchingFrame,
  notInFuture,
} from '../Contexts/DataProvider/AnalyticsData/Selections'
import { frameProjection } from '../Contexts/DataProvider/AnalyticsData/Projections'
import { DATA_HOUR_RESOLUTION } from './constants'

export type WindowSpan = 'day' | 'week' | 'month' | 'year'
export type WindowAggregation = 'last'

/**
 * Config for windowing the historical data in a aggregate chunk in reference to a larger span
 */
export type WindowConfig = {
  /**
   * The unit of time this window will be.
   * Ex: a "day" would mean the result window would be 24 hours long
   */
  span: WindowSpan
  /**
   * How to aggregated multiple chunks within the range into one window
   * Ex: "avg" would combine all the wednesdays this year by averaging every hour
   */
  aggregation: WindowAggregation
  /**
   * The reference for how the span is split and combine.
   * Ex: a span of "day" with a frame of "week" would collect every same weekday in a rnage
   */
  frame: WindowSpan
  /**
   * The span of the time frame in wich the data is search and aggregations are gathered
   * Ex: getting every weekday this "year" has a range span of year
   */
  rangeSpan: WindowSpan
  /**
   * How many times the range span is multiplied to generate the full range
   */
  rangeIterations: number
}

export function evaluateWindow(
  windowConfig: WindowConfig,
  referenceDate: Dayjs,
  data: AnalyticsDataCollection
) {
  const evaluated = data

    .select(
      // filter broader collection to specified range
      afterWindowedRange(windowConfig.rangeSpan, windowConfig.rangeIterations),
      beforeWindowedRange(windowConfig.rangeSpan, windowConfig.rangeIterations),
      // select only those matching the frame, ex "current weekday" or "current week of month"
      selectMatchingFrame(windowConfig.rangeSpan, windowConfig.frame),
      notInFuture
    )
    .sort()
    .project(frameProjection(windowConfig.span, 'avg', referenceDate))
    .sort()
  return evaluated
}

export function getReferenceDate(windowConfig: WindowConfig): Dayjs {
  return getCurrentReferenceDate().startOf(windowConfig.span)
}

/**
 * get current reference date sectioned off to the hour resolution
 */
export function getCurrentReferenceDate(): Dayjs {
  return roundDateToPreviousHourResolution(dayjs())
}

/**
 * Sections off the specified date to the hour resolution
 */
export function roundDateToPreviousHourResolution(date: Dayjs): Dayjs {
  const currentHourValue = date.hour()
  const desiredHourValue =
    Math.floor(currentHourValue / DATA_HOUR_RESOLUTION) * DATA_HOUR_RESOLUTION
  return date.startOf('hour').hour(desiredHourValue)
}
