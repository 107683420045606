import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Home, Calendar, User } from 'react-feather'
import routes from '../../util/routes'
import SidebarItem from './SidebarItem'
import { AuthContext } from '../../Contexts/AuthContext'
import { boxShadows } from '../../theme'
import Logo from '../../SharedComponents/Logos/Logo'
import _truncate from 'lodash/truncate'
import { DataContext } from '../../Contexts/DataProvider'
import LoadingBanner from '../LoadingBanner'
import ErrorBoundary from '../ErrorBoundary'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
    loader: {},
    contentRoot: {
      display: 'flex',
      width: '100%',
      minHeight: 0,
      flex: 1,
    },
    sideBarContainer: {
      width: 220,
      display: 'flex',
      boxShadow: boxShadows[1],
      paddingRight: theme.spacing(0.5),
    },
    sideBar: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxHeight: '100%',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingRight: theme.spacing(2),
      boxShadow: boxShadows[1],
    },
    mainStageContainer: {
      minWidth: 0,
      flex: 1,
      backgroundColor: theme.palette.background.default,
      height: '100%',
    },
    logoContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export interface DesktopAppContainerOwnProps {
  children: any
}

export type DesktopAppContainerProps = DesktopAppContainerOwnProps

const DesktopAppContainer = (props: DesktopAppContainerProps) => {
  const classes = useStyles()
  const userAccount = useContext(AuthContext)
  return (
    <div className={classes.root}>
      <LoadingBanner />
      <div className={classes.contentRoot}>
        <div className={classes.sideBarContainer}>
          <div className={classes.sideBar}>
            <div>
              <div className={classes.logoContainer}>
                <Logo width={180} height={80} />
              </div>
              <SidebarItem
                icon={Home}
                text="Overview"
                route={routes.app.home}
              />
              <SidebarItem
                icon={Calendar}
                text="Calendar"
                route={routes.app.calendar}
              />
            </div>
            <SidebarItem
              icon={User}
              text="Account"
              route={routes.app.account}
              subtext={_truncate(userAccount?.user?.email, { length: 20 })}
            />
          </div>
        </div>
        <div className={classes.mainStageContainer}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default React.memo(DesktopAppContainer)
