import React, { useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { CalloutCardConfig } from './types'
import { WindowConfig } from '../../../util/windowingUtils'
import useTrackWindowedProgress from '../useTrackWindowedProgress'
import CardTemplate from './CardTemplate'

export const useStyles = makeStyles((theme: Theme) => createStyles({}))

export interface CalloutCardOwnProps {
  config: CalloutCardConfig
  className?: string
}

export type CalloutCardProps = CalloutCardOwnProps

const CalloutCard = (props: CalloutCardProps) => {
  const trackWindow = useMemo(() => {
    // the input window will be the reference, get the "current" version of that window
    return {
      ...props.config.windowConfig,
      frame: props.config.windowConfig.span,
      rangeSpan: props.config.windowConfig.span,
      rangeIterations: 0,
    } as WindowConfig
  }, [props.config.windowConfig])
  const callOutProgress = useTrackWindowedProgress(
    trackWindow,
    props.config.windowConfig,
    {
      forceRelativeFrame: true,
    }
  )

  return (
    <CardTemplate
      title={props.config.title}
      callOutValue={callOutProgress.trackValue}
      className={props.className}
      progress={{
        numerator: callOutProgress.trackValue,
        denominator: callOutProgress.refValue,
      }}
    />
  )
}

export default React.memo(CalloutCard)
