import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Avatar, Typography, Box } from '@material-ui/core'
import { AuthContext } from '../../Contexts/AuthContext'
import { LogOut } from 'react-feather'
import 'firebase/auth'
import LabeledSection from './LabeledSection'
import LabeledContent from './LabeledContent'
import AccountSection from './AccountSection'
import useUserCreatedDate, {
  useHasCreateDate,
} from '../../Hooks/useUserCreatedDate'
import DataSourceSection from './DataSourceSection'
import CreateSubscriptionSection from './CreateSubscriptionSection'
import ManageSubscriptionSection from './ManageSubscriptionSection'
import AntIconButton from '../../SharedComponents/AntIconButton'
import { Button } from 'antd'
import useLogout from '../../Hooks/useLogout'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: theme.spacing(2),
      overflow: 'auto',
    },
    avatar: {
      marginRight: theme.spacing(2),
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    logoutButton: {},
    headerText: {
      fontWeight: 'bold',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },

    viewSelection: {
      width: '80%',
      maxWidth: 400,
    },
    divider: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      width: '80%',
      maxWidth: 600,
    },
    section: {
      marginTop: theme.spacing(6),
    },
    basicInfoContent: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    basicInfoLabeledSection: {
      marginRight: theme.spacing(6),
    },
    termsSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  })
)

export interface AccountOwnProps {}

export type AccountProps = AccountOwnProps

const Account = (props: AccountProps) => {
  const auth = useContext(AuthContext)
  const classes = useStyles()
  const authState = useContext(AuthContext)
  const logout = useLogout()

  const hasCreateDate = useHasCreateDate()
  const createDate = useUserCreatedDate()

  const image = auth?.additionalUserInfo?.profile?.picture
  const displayName = auth?.additionalUserInfo?.profile?.name

  const getLinkClickHandler = (url: string) => () => {
    window.location.href = url
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.headerText}>
          {'Account'}
        </Typography>
        {image && <Avatar className={classes.avatar} src={image} />}
      </div>
      <CreateSubscriptionSection className={classes.section} />
      <AccountSection label="Basic Details" className={classes.section}>
        <div className={classes.basicInfoContent}>
          <>
            {displayName && (
              <LabeledSection
                className={classes.basicInfoLabeledSection}
                label={'Account Name'}
              >
                <LabeledContent content={displayName} />
              </LabeledSection>
            )}
            {hasCreateDate && (
              <LabeledSection
                className={classes.basicInfoLabeledSection}
                label={'Creation Date'}
              >
                <LabeledContent content={createDate.format('LLL')} />
              </LabeledSection>
            )}
            {authState?.user?.email && (
              <LabeledSection
                className={classes.basicInfoLabeledSection}
                label={'Email'}
              >
                <LabeledContent content={authState.user.email} />
              </LabeledSection>
            )}
          </>
        </div>
      </AccountSection>
      <DataSourceSection className={classes.section} />
      <ManageSubscriptionSection className={classes.section} />
      <Box width="100%" display="flex" justifyContent="center" marginTop={4}>
        <AntIconButton size="large" Icon={LogOut} onClick={logout}>
          {'Logout of Swell Stats'}
        </AntIconButton>
      </Box>
      <div className={classes.termsSection}>
        <Button
          type="link"
          onClick={getLinkClickHandler(
            'https://swellstats.com/privacy-policy/'
          )}
        >
          {'Privacy Policy'}
        </Button>
        <Button
          type="link"
          onClick={getLinkClickHandler(
            'https://swellstats.com/terms-of-service/'
          )}
        >
          {'Terms of Service'}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(Account)
