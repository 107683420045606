import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import _noop from 'lodash/noop'
import { Snackbar } from '@material-ui/core'
import { Alert as MUIAlert } from '@material-ui/lab'

export type Alert = {
  type: 'success' | 'error'
  message: string
}

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface NotificationsOwnProps {
  children: any
}

export type NotificationsProps = NotificationsOwnProps

export type CreateAlert = (type: 'success' | 'error', message: string) => void

export const AlertContext = React.createContext<CreateAlert>(_noop)

const Notifications = (props: NotificationsProps) => {
  const [alertQueue, updateAlertQueue] = useState<Alert[]>([])
  const enqueueAlert: CreateAlert = (
    type: 'success' | 'error',
    message: string
  ) => {
    updateAlertQueue([...(alertQueue || []), { type, message }])
  }

  const dequeueAlert = () => {
    const [, ...remainingAlerts] = alertQueue || []
    updateAlertQueue(remainingAlerts)
  }

  const currentAlert = alertQueue[0]

  return (
    <AlertContext.Provider value={enqueueAlert}>
      {props.children}
      <Snackbar
        open={!!currentAlert}
        autoHideDuration={6000}
        onClose={dequeueAlert}
      >
        {currentAlert && (
          <MUIAlert
            onClose={dequeueAlert}
            severity={currentAlert?.type === 'success' ? 'success' : 'error'}
          >
            {currentAlert?.message || ''}
          </MUIAlert>
        )}
      </Snackbar>
    </AlertContext.Provider>
  )
}

export default React.memo(Notifications)

export function useAlert(): CreateAlert {
  return React.useContext(AlertContext)
}
