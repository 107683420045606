import { useCountUpProps, useCountUp } from 'react-countup'
import { useData } from '../Contexts/DataProvider/useDataAggregation'
import { useEffect } from 'react'

function useDataBoundCountUp(value: number, countUpProps?: useCountUpProps) {
  const { isLoading } = useData()
  const { countUp, update, start } = useCountUp({
    ...(countUpProps || {}),
    end: value,
    start: 0,
    duration: 0.7,
  })

  useEffect(() => {
    update(value)
  }, [isLoading, value, update, start])

  return Number(countUp)
}

export default useDataBoundCountUp
