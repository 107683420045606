import dayjs, { Dayjs } from 'dayjs'
import { Channels, Metric } from '../../util/queryUtil'
import { useContext, useMemo, useState } from 'react'
import { UserAccountsContext } from '../../SharedComponents/AccountInitialization/Contexts'
import GoogleAPIService from '../../services/GoogleAPIService'
import useAlertRequestTriggers from '../../Hooks/useAlertRequestTriggers'
import useAccessToken from '../../Hooks/useAccessToken'
import { useCustomCompareEffect } from 'react-use'
import _isEqual from 'lodash/isEqual'
import AnalyticsDataCollection from './AnalyticsData/AnalyticsDataCollection'
import useLocalStorageState from '../../Hooks/useLocalStorageState'
import {
  PersistedDatasets,
  reconcilePersistedDataSetUpdates,
} from './PersistedData'
import DataLoader, { LoadCallbacks } from './DataLoader'

export type UseAPIQueryResult = {
  isLoading: boolean
  result: AnalyticsDataCollection
}

export type Range = {
  start: Dayjs
  end: Dayjs
}

export type APIValues = {
  metric: Metric
  channel: Channels | 'all'
}

export type APIValuesAndView = {
  viewId: string | null
} & APIValues

let globalAPIValuesAndViews: APIValuesAndView | null = null

export default function useAPIQuery(
  value: APIValues,
  dateRange: Range
): UseAPIQueryResult {
  const userAccounts = useContext(UserAccountsContext)
  const accessToken = useAccessToken()
  const alertRequestTriggers = useAlertRequestTriggers(
    null,
    'Error loading data'
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const googleAPIService = useMemo(
    () => new GoogleAPIService(accessToken as string),
    [accessToken]
  )

  const apiValuesAndView: APIValuesAndView = useMemo(
    () => ({
      viewId: userAccounts.selectedView?.id || null,
      metric: value.metric,
      channel: value.channel,
    }),
    [userAccounts.selectedView?.id, value.channel, value.metric]
  )
  globalAPIValuesAndViews = apiValuesAndView

  /*
    persisted datasets represent data in local storage.  data is
    only persisted in local storage when a loading sequence has completed
    successfully and there are no gaps in the data
  */
  const [persistedDatasets, setPersistedDatasets] = useLocalStorageState<
    PersistedDatasets
  >('api-data-2', [])

  /*
    The working dataset represents the view data.  This data will incrementally load
    and may contain gaps within the data set.  It is only persisted upon a successful load
    completion
  */
  const [workingDataset, setWorkingDataset] = useState<AnalyticsDataCollection>(
    () => AnalyticsDataCollection.initEmpty()
  )

  useCustomCompareEffect(
    () => {
      setIsLoading(true)
      if (!userAccounts.isInitialized || !userAccounts.selectedView?.id) return

      async function loadData() {
        const targetPersistedDataset = persistedDatasets.find((_) =>
          _isEqual(_.apiValuesAndView, apiValuesAndView)
        )

        let startDataset = targetPersistedDataset
          ? AnalyticsDataCollection.initFromPersistedDataset(
              targetPersistedDataset
            )
          : AnalyticsDataCollection.initEmpty()
        setWorkingDataset(startDataset)

        if (targetPersistedDataset) {
          const refreshDataTime = dayjs(targetPersistedDataset.nextRequestDate)
          if (refreshDataTime.isAfter(dayjs())) return
        }

        const loader = new DataLoader(
          googleAPIService,
          apiValuesAndView,
          alertRequestTriggers
        )

        const loadCallbacks: LoadCallbacks = {
          onProgressReported: (collection) => {
            startDataset = startDataset.mergeWithNewAPIData(collection)
            setWorkingDataset(startDataset)
          },
          shouldStillLoad: () =>
            _isEqual(apiValuesAndView, globalAPIValuesAndViews),
          onCompletion: () => {
            const newPersistedData = reconcilePersistedDataSetUpdates(
              persistedDatasets,
              startDataset,
              apiValuesAndView
            )
            setPersistedDatasets(newPersistedData)
          },
        }

        let loadRange = DataLoader.reconcileLoadRangeFromPersistedDataset(
          targetPersistedDataset
        )
        await loader.loadRange(loadRange, loadCallbacks)
      }

      loadData().finally(() => setIsLoading(false))
    },
    [
      value,
      userAccounts,
      dateRange,
      googleAPIService,
      persistedDatasets,
      setIsLoading,
    ],
    ([prevValue, prevUserAccounts], [value, userAccounts]) =>
      _isEqual(prevValue, value) && _isEqual(prevUserAccounts, userAccounts)
  )

  return {
    isLoading,
    result: workingDataset,
  }
}
