import { useContext } from 'react'
import { DataContext } from '../Contexts/DataProvider'

function useHasNoData() {
  const { isLoading, data } = useContext(DataContext)

  return !isLoading && !data.dataPoints.length
}

export default useHasNoData
