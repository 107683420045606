import { useContext } from 'react'
import { AuthContext } from '../Contexts/AuthContext'
import dayjs, { Dayjs } from 'dayjs'

const defaultValue = dayjs()

const useRawDateValue = (): number | null => {
  const auth = useContext(AuthContext)
  const rawDateValue = auth?.user?.createdAt

  if (!rawDateValue) return null

  const asNumber = Number(rawDateValue)

  if (isNaN(asNumber)) return null

  return asNumber
}

export const useHasCreateDate = (): boolean => {
  return useRawDateValue() !== null
}

const useUserCreatedDate = (): Dayjs => {
  const rawValue = useRawDateValue()

  return rawValue === null ? defaultValue : dayjs(rawValue)
}

export default useUserCreatedDate
