import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Home, Calendar, User } from 'react-feather'
import routes from '../../util/routes'
import MenuItem from './MenuItem'
import { boxShadows } from '../../theme'
import LoadingBanner from '../LoadingBanner'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
    bottomMenuContainer: {
      height: 70,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: boxShadows[1],
      marginTop: theme.spacing(0.5),
      backgroundColor: theme.palette.common.white,
    },
    mainStageContainer: {
      minHeight: 0,
      flex: 1,
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
  })
)

export interface DesktopAppContainerOwnProps {
  children: any
}

export type DesktopAppContainerProps = DesktopAppContainerOwnProps

const DesktopAppContainer = (props: DesktopAppContainerProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <LoadingBanner />
      <div className={classes.mainStageContainer}>{props.children}</div>
      <div className={classes.bottomMenuContainer}>
        <MenuItem icon={Home} text="Overview" route={routes.app.home} />
        <MenuItem icon={Calendar} text="Calendar" route={routes.app.calendar} />
        <MenuItem icon={User} text="Account" route={routes.app.account} />
      </div>
    </div>
  )
}

export default React.memo(DesktopAppContainer)
