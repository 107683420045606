import React from 'react'
import {
  Paper as MUIPaper,
  PaperProps as MUIPaperProps,
} from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 10,
    },
    addBorder: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  })
)

export interface PaperOwnProps {
  addBorder?: boolean
}

export type PaperProps = PaperOwnProps & MUIPaperProps

const Paper = ({ className, addBorder, ...muiPaperProps }: PaperProps) => {
  const classes = useStyles()

  return (
    <MUIPaper
      className={clsx(className, classes.root, addBorder && classes.addBorder)}
      {...muiPaperProps}
    />
  )
}

export default React.memo(Paper)
