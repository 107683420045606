import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { motion, Variants } from 'framer-motion'
import MobileHeader from '../../../SharedComponents/MobileHeader'
import { Typography } from '@material-ui/core'
import GlobalFilterButton from '../../../SharedComponents/GlobalFilterButton'
import dayjs from 'dayjs'
import CalendarButton from './CalendarButton'
import Performance from '../Performance'
import Stats from '../Stats'
import WeekTotalTable from '../IsoWeekBreakdown/WeekTotalTable'
import FreeTrialWarning from '../../../SharedComponents/FreeTrialWarning'
import SubscriptionGaurd from '../../../SharedComponents/SubscriptionGaurd'
import SwitchMetricPrompt from '../../../SharedComponents/SwitchMetricPrompt'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    mobileHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    iconButtons: {
      display: 'flex',
      alignItems: 'center',
    },
    mainContent: {
      minHeight: 0,
      flex: 1,
      width: '100%',
      overflowY: 'auto',
      padding: theme.spacing(1),
    },
    cardContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    dateSelectIcon: {
      marginRight: theme.spacing(1),
    },
    homeItem: {
      marginBottom: theme.spacing(2),
    },
    graph: {
      height: '70vh',
    },
    card: {
      width: '100%',
      marginRight: 0,
    },
    totalTable: {
      marginTop: theme.spacing(4),
    },
    freeTrialWarning: {
      marginBottom: theme.spacing(2),
    },
    switchMetricPrompt: {
      marginBottom: theme.spacing(2),
    },
  })
)

export interface MobileCalendarOwnProps {}

export type MobileCalendarProps = MobileCalendarOwnProps

const MobileCalendar = (props: MobileCalendarProps) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState(() => dayjs())

  return (
    <SubscriptionGaurd>
      <div className={classes.root}>
        <MobileHeader className={classes.mobileHeader}>
          <Typography variant="h6">
            {selectedDate.format('MM/DD/YYYY')}
          </Typography>
          <div className={classes.iconButtons}>
            <CalendarButton
              value={selectedDate}
              onChange={setSelectedDate}
              className={classes.dateSelectIcon}
            />
            <GlobalFilterButton />
          </div>
        </MobileHeader>
        <div className={classes.mainContent}>
          <FreeTrialWarning className={classes.freeTrialWarning} />
          <SwitchMetricPrompt className={classes.switchMetricPrompt} />
          <Performance focusedDate={selectedDate} />
          <WeekTotalTable
            focusedDay={selectedDate}
            className={classes.totalTable}
          />
          <Stats focusedDate={selectedDate} cardClassName={classes.card} />
        </div>
      </div>
    </SubscriptionGaurd>
  )
}

export default React.memo(MobileCalendar)
