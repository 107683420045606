export default {
  googleAuthClientId:
    '253701462010-ejj5uphhqe48hbq52r9kcgeh06bconbh.apps.googleusercontent.com',
  scopes:
    'email profile openid https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics', // https://console.cloud.google.com/apis/credentials?project=swell-stats
}

export const firebaseConfig = {
  apiKey: 'AIzaSyBFz4thptLJz8eCtNXqZSX7rGfoUbxaduk',
  authDomain: 'swell-stats.firebaseapp.com',
  databaseURL: 'https://swell-stats.firebaseio.com',
  projectId: 'swell-stats',
  storageBucket: 'swell-stats.appspot.com',
  messagingSenderId: '253701462010',
  appId: '1:253701462010:web:1d322e1831b57edcd4f643',
  measurementId: 'G-XY8HWFVX76',
}

export const DATA_HOUR_RESOLUTION = 1
