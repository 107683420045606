import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { AlertCircle } from 'react-feather'

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {},
  })
)

export interface ErrorBoundaryOwnProps {
  errorMessage?: string
  children: any
}

interface ErrorBoundaryState {
  thrownError: Error | null
}

export type ErrorBoundaryProps = ErrorBoundaryOwnProps

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { thrownError: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { thrownError: error }
  }

  componentDidCatch(error: Error) {
    console.log('Error boundary caught error', error)
  }

  render() {
    if (!!this.state.thrownError) {
      // You can render any custom fallback UI
      return (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box marginRight={2}>
            <AlertCircle />
          </Box>
          <Typography variant="body1">
            {this.props.errorMessage || 'An error occurred'}
          </Typography>
        </Box>
      )
    }

    return this.props.children
  }
}

export default React.memo(ErrorBoundary)
